﻿@import '_variables';

@media print {


    .noPrint {
        display: none;
    }

    .printOnly {
        display: block;
        height: auto;
        width: auto;
    }

    // Remove the hyperlink text that chrome / bootstrap etc insert
    a[href]:after {
        content: none !important;
    }

    /* Hide these elements */
    .topHeader {
        display: none !important;
    }

    .mobile,
    .topHeader,
    header nav,
    header .search,
    footer,
    video, audio, object, embed {
        display: none !important;
        height: 0 !important;
    }

    header .header.stuck {
        position: relative !important;
    }

    img {
        max-width: 100%;
    }

    @page {
        margin: 0.5cm;
    }

    body {
        line-height: $lineHeight;
    }

    h1 {
        font-size: 21pt;
    }

    h2 {
        font-size: 15pt;
        margin-top: 25px;
    }

    h3 {
        font-size: 13pt;
        margin-top: 20px;
    }

    p {
        a {
            color: black;
            word-wrap: break-word;

            &:after {
                content: " " attr(href) ")";
            }

            &[href^="#"]:after {
                display: none;
            }

            &[href^="http://"]:after, a[href^="https://"]:after {
                content: " (" attr(href) ")";
            }
        }
    }

    .pageTopper {
        min-height: 0;

        .topperImage {
            display: none;
        }
    }

    .mainContent {
    }

    .secondaryContent {
    }

    .resources {
        .innerContent .itemImage {
            display: none;
        }
    }

    .filteredContent {
        .filtersContainer, #loadingIndicator, #loadMoreContainer {
            display: none !important;
        }
    }

    .viewMoreOuter {
        display: none;
    }

    .teammembers {
        .teammember {
            .bgImgWrapper {
                display: none;
            }
        }
    }

    .teammember {
        .topperGrid {
            height: auto;

            .bioTopContent.flexCol {
                color: #000;
                background-color: #fff;

                .bioContact {
                    text-align: left;
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    height: 100%;

                    h1 {
                        color: #000;
                    }

                    a:link, a:visited, a:active {
                        color: #000;
                    }

                    .offices {
                        ul {
                            li {
                                border-right: 1px solid #000;

                                a {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }
        }

        .bgImgWrapper {
            display: none;
            height: 0;
        }
    }

    .expertise.detailView, .sector.detailView, .solution.detailView {
        .pageTopper {
            min-height: 0;
            padding-top: 2rem;

            .topperImage {
                display: none;
            }
        }
    }
}



.printWrapper {
    font-size: 12px;

    .container {
        width: 100%;
        padding: 0;
        margin: 0;
        max-width: 100% !important;
    }

    .baseFont {
        font-family: $primaryFont;
    }

    h2 {
        break-inside: avoid;
    }

    h2::after { // basically give the header an extra element that is large enough that it will break if needed.
        //NOTE: need break-inside: avoid on the parent element
        content: "";
        display: block;
        height: 120px;
        margin-bottom: -120px;
    }

    h2.superMargin::after { // basically give the header an extra element that is large enough that it will break if needed.
        //NOTE: need break-inside: avoid on the parent element
        content: "";
        display: block;
        height: 500px;
        margin-bottom: -500px;
    }


    h2.mediumMargin::after { // basically give the header an extra element that is large enough that it will break if needed.
        //NOTE: need break-inside: avoid on the parent element
        content: "";
        display: block;
        height: 300px;
        margin-bottom: -300px;
    }

    .logo {
        margin:30px 0 0 0;
        display: block;
        width: 100%;
        padding: 0
    }


    .listView.teammembers {
        display: block;
        width: 100%;

        > li {
            display: inline-block;
            width: 205px;
            margin: 5px 3px;

            .cardView.teammember {
                width: 100%;

                ul.contactInfo {
                    font-size: 11px;
                }
                //break-inside: avoid;
            }
        }
    }

    .simpleViewWrapper {
        .listView.teammembers.simpleView {
            display: initial;
            width: 100%;
            gap: initial;

            > li {
                display: inline-block;
                width: 205px;
                margin: 5px 3px;

                .cardView.teammember {
                    width: 100%;
                    min-height: 140px;
                    break-inside: avoid;
                }
            }
        }
    }

    .detailView {
        //margin-top: 2rem;

        .mainContent {
        }
    }


    .detailView.teammember {
        display: block;
        position: relative;
        padding-top: 0 !important;


        .bioTopContent {
            padding: 0;
            grid-template-columns: 45% 55%;
            gap: 5px;

            .bioContact {
                padding: 0 1.5rem;
                width: 100%;
                position: relative;
                overflow: hidden;
                justify-content: flex-start;

                a {
                    border-bottom: none;
                }

                .nameDesignationsPronunciation {
                    margin: 2rem 0 0 0;

                    h1 {
                        font-size: 26px;
                        padding: 0;
                        background: none;
                        margin: 0;
                    }
                }

                .position {
                    font-size: 1rem
                }


                .contactInfo {
                    font-size: 0.8rem;
                    margin: 0.75rem 0 0.75rem 0;

                    .contactItemWrapper {
                        .prefixLabel {
                            font-size: 0.8rem;
                        }
                    }
                }

                img.wavelines {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: scaleX(-2);
                    background-size: cover;
                    height: auto;
                    width: 100%;
                    opacity: 0.3;
                    z-index: 1;
                }

                &:after {
                    display: none;
                }
            }

            .bioMobileButtons {
                display: none;
            }
        }

        .sidebarGrid {
            grid-template-columns: 2fr 1fr;
            gap: 0rem;

            .mainContent {
                margin: 0;
                padding: 0rem 3rem 2rem 2rem;

                h2 {
                    font-size: 20px;
                }

                h2#overview {
                    margin-top: 0;
                    font-weight: 700;
                }

                .cmsContent.highlightFirstElement > :first-child {
                    font-size: 16px !important;
                }
            }

            .sideContent {
                .innerWrapper {
                    padding: 1.5rem;
                }

                h2 {
                    font-size: 14px;
                    margin: 0 0 10px 0;
                }
            }
        }

        .greyWrapper.wrapper{
            padding:2rem 0;
        }
        .related.expertisesWrapper {
            padding: 0 2rem;

            h2 {
                font-size: 20px;
                font-weight: 700;
            }

            .listView{
                display:flex;
                grid-template-columns: initial;
                gap: 2rem;
                flex-wrap: wrap;
            }
        }
    }


    .detailView.insight {
        .topperContent {
            margin: 2rem 0;
            padding-bottom: 2rem;
            border-bottom: solid 1px $secondaryColour;
            color: #000;

            h1 {
                font-size: 2.8rem;
                margin-top: .25rem;
            }

            span {
                color: #000;
            }

            .contentTypeLabels {
                font-size: 1rem;
                margin-bottom: 0;
                color: $secondaryColourDark;
            }

            .dates {
                font-size: .9rem;
                color: #000;
            }

            .listView.teammembers {
                display: inline-flex;
                margin: 0;

                .teammember {
                    background-color: transparent;
                    font-size: .9rem;

                    a {
                        text-decoration: none;
                        color: #000;
                    }
                }

                .teammember + .teammember {
                    &:before {
                        content: ', ';
                    }
                }
            }
        }

        .topperImage {
            .bgImgWrapper {
                height: 300px;
            }
        }
    }
}
