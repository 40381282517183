﻿/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />
/// <reference path="_placeholders.scss" />
/// <reference path="_grids.scss" />

/****************************************************************************************************/
/* #region Flex Grid */
/****************************************************************************************************/

.grid {
    /*width: 100%;*/ // 100% width seems to cause problems with template layout grids ...
    //width: calc(100% + #{$gutterWidth}); // this does not work (entirely) to fix the above issue
    width: initial; // seems to need to be this way
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 (($gutterWidth/2) * -1) 0 (($gutterWidth/2) * -1);

    &.col {
        width: auto;
    }

    // Use this modifier to remove gutters
    &.grid-nogutter {
        margin: 0;

        & > .col {
            margin: 0;
        }
    }

    &.grid-nowrap {
        flex-wrap: nowrap;
    }
    // Full-width first child
    &.featureFirst {
        > .col:first-child {
            @include col(1);
        }
    }

    &:not(.col) {
        // any grid which is not itself a column should span the full width of the parent (in case of collapsing on auto-width children).
        // ToDo: double-check if this causes a problem with gutters
        flex-grow: 1;
    }

    > .col {
        margin: 0 ($gutterWidth/2) 0 ($gutterWidth/2);
    }
}

// BASE COLUMN CLASS
.col {
    min-width: 0;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    //align-items: flex-start; // prevent items from being stretched horizontally
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Grid + Column Alignment */
/****************************************************************************************************/

/* Justify Content Classes */
.grid-start,
.col-top {
    justify-content: flex-start;
}

.grid-end,
.col-bottom {
    justify-content: flex-end;
}

.grid-center,
.col-middle {
    justify-content: center;
}

.grid-evenly,
.col-evenly {
    justify-content: space-evenly;
}

.grid-around,
.col-around {
    justify-content: space-around;
}

.grid-between,
.col-between {
    justify-content: space-between;
}


/* Align Items Classes */
.col-align-start,
.grid-align-start {
    align-items: flex-start;
}

.col-align-end,
.grid-align-end {
    align-items: flex-end;
}

.col-align-center,
.grid-align-center {
    align-items: center;
}

.col-align-stretch,
.grid-align-stretch {
    align-items: stretch;
}

.col-align-baseline,
.grid-align-baseline {
    align-items: baseline;
}

/* Column Self-Alignment Classes */
.col-align-top {
    align-self: flex-start;
}

.col-align-bottom {
    align-self: flex-end;
}

.col-align-middle {
    align-self: center;
}

/* Reordering Columns */
.col-first {
    order: -1;
}

.col-last {
    order: 1;
}

.grid-reverse-columns {
    flex-direction: column-reverse;
}

.grid-reverse-rows {
    flex-direction: row-reverse;
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Column Sizing */
/****************************************************************************************************/

//Fixed Column Class
.col-fixed {
    flex: initial;
    flex-shrink: 0;
}

// Auto width
.col-auto-width {
    flex: 0 1 auto;
}

//Grow Based Columns
@for $i from 2 through 11 {
    .col-grow-#{$i},
    .grid.col-grow-#{$i} {
        flex-grow: $i;
    }
}

//Percent Based Columns
@for $i from 1 through 12 {
    .col-#{$i} {
        flex-basis: (100% / 12) * $i;
        max-width: (100% / 12) * $i;
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Responsive Grids */
/****************************************************************************************************/

// resizable grid responsive settings
.grid[data-gutter-width] {
    flex-wrap: nowrap;

    > .col {
    }
    // shared column stacking styles applied at different breakpoints
    @mixin colStackedStyles($breakpoint) {
        @media (max-width: $breakpoint) {
            flex-wrap: wrap;
            margin-left: 0 !important;
            margin-right: 0 !important;

            > .col {
                margin-left: 0 !important;
                margin-right: 0 !important;
                width: 100% !important;
                max-width: initial !important;
                flex-basis: initial !important;
            }
        }
    }

    .sfPageEditor & {
        > .col {
            flex-grow: 1 !important;
            flex-shrink: 0 !important;
            flex-basis: 0 !important;
        }
    }

    &:not([data-media-breakpoint]) { // no data-media-breakpoint attribute set
        /*@media (max-width: $M_Max) {
            @extend %colStackedStyles;
        }*/
    }

    &[data-media-breakpoint="1299px"],
    &[data-media-breakpoint="1300px"] {
        @include colStackedStyles($L_Max);
    }

    &[data-media-breakpoint="1024px"] {
        @include colStackedStyles($M_Max);
    }

    &[data-media-breakpoint="767px"] {
        @include colStackedStyles($S_Max);
    }

    &[data-media-breakpoint="500px"] {
        @include colStackedStyles($XS_Max);
    }
}

/*
@media only screen and (max-width: $M_Max) {
    .grid {
        &:not([data-gutter-width]):not(.grid-nowrap) { // only apply to NON-resizable grids
            .col {
                flex: 100%;
                max-width: 100%;
            }
        }
    }
}
*/

/* #endregion */
/****************************************************************************************************/