﻿/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />
/// <reference path="_placeholders.scss" />
/// <reference path="_grids.scss" />
/* 
Media queries for tablet and mobile (general guidelines for breakpoints globally set in the _variables file) 
Mobile ($S_Max)   Tablet ($M_Min and $M_Max)  Small Desktop ($L_Min and $L_Max)  Large Desktop ($XL_Min - This is the standard for all styles)
*/

/* Large Desktop (switch between simple and mobile nav) */
@media only screen and (min-width: $XL_Min) {
}

/* Small Desktop (switch between simple and mobile nav) */
@media only screen and (max-width: $L_Max) {
    nav.mainNavWrapper ul.mainNav.k-menu li.rootMenuItem > a, nav.mainNavWrapper ul.mainNav.k-menu:not(.k-context-menu) li.rootMenuItem > a {
        padding: 0.5rem 1.4rem;
    }
}


/* Tablet */
@media only screen and (max-width: $M_Max) {
    .hamburger {
        display: block !important;
    }

    #header {
        .mainNavWrapper {
            display: none !important;
        }

        .topNav {
            padding-right: 3rem;
            align-items: center;
        }
    }

    .homePage {
        .teammembersWrapper {
            margin-bottom: 3rem;
        }
    }

    .homeSlideshow.flat .splide__slide {
        min-height: 550px;
    }

    .topperSection:has(.topperImage) {
        min-height: 340px;
    }

    .detailView .sidebarGrid {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 3rem;
    }

    .mainExpertise ul.listView.expertises {
        gap: 1rem;

        .cardView.expertise {
            display: grid;
            grid-template-rows: 1fr;
            gap: 1rem;
        }
    }

    main .listView.offices {
        .cardView.office {
            .cardContent {
                width: 70%;
            }

            .imgWrapper {
                width: 30%;
            }
        }
    }

    .siteFooter {
        .mainFooter {
            .mainFooterGrid {
                .mainFooterRight {
                    .officesWrapper {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    .detailView .topperSection h1 {
        font-size: 2rem;
    }
    h1.pageTitle {
        font-size: 2.25rem;
    }
}

/* Mobile Only */
@media only screen and (max-width : $S_Max) {
    #header {
        .topHeader {
            .topHeaderGrid {
            }
        }
    }

    .k-treeview {
        .k-item {
            font-size: 1.6rem;
        }
    }

    .homeSlideshow.flat .splide__slide {
        min-height: 500px;
    }

    .insightsWrapper .listView.insights .cardView.insight {
        grid-template-columns: 1fr 50px;
        grid-column-gap: 1.25rem;
        grid-row-gap: 1.25rem;

        h3 {
            grid-area: 1 / 1 / 2 / 2;
        }

        .summary {
            grid-area: 2 / 1 / 3 / 2;
        }

        .button {
            grid-area: 1 / 2 / 3 / 3;
        }
    }

    .detailView.teammember .bioTopContent {
        grid-template-columns: 1fr;

        .bioContact {
            order: 1;

            h1 {
                br {
                    display: none;
                }
            }
        }
    }

    .detailView .sidebarGrid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .siteFooter {
        .mainFooter {
            .mainFooterGrid {
                .mainFooterRight {
                    .officesWrapper {
                        .offices {
                            grid-template-columns: 1fr;

                            .cardView.office .cardContent {
                                margin-top: 1rem;

                                h3 {
                                }

                                .prefixLabel {
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .paxxl{
        padding: 2rem !important;
    }

}
/* XS Mobile Only */
@media only screen and (max-width : $S_Mid) {
    #header {

        .topNavWrapper {
            .topNav {
                .socialLinks {
                    display: none;
                }
            }
        }
    }

    main .bd .breadCrumbInner {
        font-size: 1rem;
        border-radius: 0;

        span.current {
            background-color: transparent;
            border-radius: 0;
            padding: 0;
        }
    }

    .borderRadiusGreyWrapper {
        padding: 2rem;
        
    }

    .homeSlideshow.flat .splide__slide {
        min-height: 450px;
    }

    main .listView.offices {
        .cardView.office {
            .cardContent {
                width: 100%;
            }

            .imgWrapper {
                width: 0%;
                display: none;
            }
        }
    }
    .threeOne.flexReverse {
        display: flex;
        flex-direction: column;
        order: initial;
        grid-area: initial;

        .listViewTitle {
            order: initial;
            grid-area: initial;
        }

        .viewAllMoreWrapper {
            margin-top: 1rem;
        }

        .teammembersWrapper.splide.related .splide__arrows {
            bottom: 12rem;
        }
    }

    .related.expertisesWrapper {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .detailView .topperSection h1 {
        font-size: 1.5rem;
    }
 
}

@media only screen and (max-width : $XS_Max) {
    #header {
        .logoGrid {
            .topNavWrapper {
                .topNav {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .borderRadiusGreyWrapper
{
        padding:1rem 1rem;
    }

    .homePage .careersWrapper .primaryWrapper {
        padding: 2rem;
    }
        .mainExpertise ul.listView.expertiseandsectors {
            margin: 1rem 0 !important;

            li {
                .cardView.expertise a.button {
                    max-width: 100% !important;
                    padding-right: 2.75rem;
                }
            }
        }

        #printModal {
            padding: 1rem;
        }

        .greyWrapper{
            .cmsContent{
                h3{
                    font-size: 1.5rem
                }
            }
        }
    }
