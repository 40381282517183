﻿@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(-0%);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes header-slide-in {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}
@keyframes BlurIn {
    0% {
        filter: blur(15px);
    }

    100% {
        filter: blur(0);
    }
}
@keyframes ColourIn {
    0% {
        color:#fff;
    }

    100% {
        color: #455C65;
    }
}

@-webkit-keyframes move {
    0% {
        transform-origin: bottom left;
        transform: scale(1.0);
    }

    100% {
        transform: scale(1.2);
    }
}