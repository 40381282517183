﻿/// <reference path="_variables.scss" />
/// <reference path="_grids.scss" />

/****************************************************************************************************/
/* #region Buttons */
/****************************************************************************************************/

/*
    Button styles were moved from placeholders to here to reduce generated CSS selector length.
    Ideally you should always either use a real <button> or add the .button class(es) where you needed,
    BUT if you really need it (perhaps to override some 3rd-party styles) you can always do this:
    
    #specific_selector .my-custom-button {
        @include button; // default styles
        // or
        @include button("secondary", true); // predefined styles WITH base button styles
    }

    You can also "extend" button classes defined in global.scss if you need to (similar to the old way).
    This keeps the css nice and light AS LONG as it's not super-nested beneath some comma-separated parents

    #specific_selector .my-custom-button {
        @extend .button.secondary;
    }
*/

@mixin button($style: "default", $modifier: "", $includeDefaultStyles: true) {
    /* Default / Base button styles - only include these when you really need them */
    /* This is what you'll get when you just call @include button; (but you should avoid using this mixin altogether if you can) */
    @if($style == "default" or $includeDefaultStyles) {
        color: $buttonTextColour;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        /*padding: 0.9rem 1.4em;*/
        padding: 0.8em 2em;
        margin-top: 1em;
        font-size: 0.9rem;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none !important;
        border: $buttonBorderWidth $buttonBorderStyle $buttonBorderColour;
        border-radius: $buttonBorderRadius;
        background-color: $primaryColour;
        transition: $transition;
        white-space: nowrap;
        letter-spacing: 0.1em; //$letterSpacing;
        height: auto;
        width:fit-content;

        &:after { // icon base
            width: 1em;
            height: 1em;
            display: inline-block;
            content: initial;
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 0.6em;
        }
        /* FontAwesome Icons in markup */
        [class*="fa-"] {
            margin-left: 0.6rem;
        }

        &:hover,
        &:focus {
            color: white;
            background-color: darken($secondaryColour, 5%);
            text-decoration: none !important;
        }

        &:active {
            color: white;
            text-decoration: none !important;
            background-color: darken($secondaryColour, 10%);
        }
    }
    /* Reverse (flip the colours of the standard button */
    @if($style == "reverse") {
        background-color: $secondaryColour;
        color: white;
        border: $buttonBorderWidth solid $secondaryColour;

        &:hover {
            background-color: white;
            color: $secondaryColour;
        }

        &:active {
            background-color: white;
            color: $secondaryColour;
        }
    }

    @if($style == "dark") {
        background-color: $secondaryColour;
        border: $buttonBorderWidth solid $secondaryColour;
        color: white;

        &:hover,
        &:focus {
            border-color: darken($secondaryColour, 10%);
            background-color: darken($secondaryColour, 10%);
        }

        &:active {
            border-color: darken($secondaryColour, 10%);
            background-color: darken($secondaryColour, 10%);
        }
    }
    /* Outline */
    @if($style == "outline") {
        background-color: white;
        color: $secondaryColour;
        border: $buttonBorderWidth solid $primaryColour;

        &:hover {
            background-color: $secondaryColour;
            color: white;
        }

        &:active {
            color: white;
            background-color: darken($secondaryColour, 10%);
        }
    }
    /* Arrow */
    @else if($style == "arrow") {
        &:after {
            content: ' ';
            background-image: url(/CFImages/Icons/arrow-right.svg);
        }

        &:hover,
        &:focus,
        &:active {
            &:after {
                background-image: url(/CFImages/Icons/arrow-right-white.svg);
            }
        }
    }
    /* Large */
    @else if($modifier == "large") {
        font-size: 1.2rem;
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Link Styler */
/****************************************************************************************************/

@mixin link($style: "default", $modifier: "", $includeDefaultStyles: true) {
    /* Default / Base button styles - only include these when you really need them */
    /* This is what you'll get when you just call @include button; (but you should avoid using this mixin altogether if you can) */
    @if($style == "default" or $includeDefaultStyles) {
        color: $primaryColour;
        cursor: pointer;
        background: none;
        text-decoration: underline;

        &:after { // icon base
            width: 1em;
            height: 1em;
            display: inline-block;
            content: initial;
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 0.6em;
        }
        /* FontAwesome Icons in markup */
        [class*="fa-"] {
            margin-left: 0.6rem;
        }

        &:hover, &:focus {
            color: darken($primaryColour, 5%);
            text-decoration: underline;
        }

        &:active {
            color: darken($primaryColour, 15%);
            text-decoration: underline;
        }
    }
    @if($style == "grey") {
        color: $grey;
        cursor: pointer;
        background: none;
        text-decoration: none;

        &:hover, &:focus {
            color: darken($primaryColour, 5%);
            text-decoration: underline;
        }

        &:active {
            color: darken($primaryColour, 15%);
            text-decoration: underline;
        }
    }
    @else {
        /* Default EXTRAS */
        /*
            Any ADDITIONAL link styles to add ONLY IF no style was specified. Most default link
            styles should be defined at the top unless you wouldn't want them on predefined types.
        */
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Functions */
/****************************************************************************************************/

/* Remove units from a value */
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

/* Convert pixels to rems */
@function pixelsToRems($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

/* Convert rems to pixels */
@function remsToPixels($size) {
    $pxSize: $size * 16;
    @return $pxSize * 1rem;
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Shortcut Selectors */
/****************************************************************************************************/

/*
    Set focus styles ONLY for users that need it.
    Will not work on older browsers (see base focus styles for example of overriding).
*/
@mixin focusVisible {
    &:focus:focus-visible {
        @content
    }
}

/*  Just an alias for focusVisible */
@mixin focus {
    @include focusVisible {
        @content
    }
}

/*
    Focus AND Hover
    A shortcut for when you need to share styles between focus and hover.
    Most of the time styles should go into separate focusVisible and/or :hover rules as needed.
*/
@mixin focusAndHover {
    @include focusVisible {
        @content
    }

    &:hover {
        @content
    }
}

/* Select all FontAwesome icons */
@mixin icons {
    [class^="fa-"] {
        @content
    }
}

/* Shortcut for writing out h1-h6 */
@mixin headings {
    h1, h2, h3, h4, h5, h6 {
        @content
    }
}

/* Cross-browser placeholders */
@mixin placeholder {
    #{if(&, "&", "")}::-webkit-input-placeholder {
        @content
    }

    #{if(&, "&", "")}:-moz-placeholder {
        @content
    }

    #{if(&, "&", "")}::-moz-placeholder {
        @content
    }

    #{if(&, "&", "")}:-ms-input-placeholder {
        @content
    }
}

// Text selection (cross-browser)
@mixin selection {
    #{if(&, "&", "")}::selection {
        @content;
    }

    #{if(&, "&", "")}::-moz-selection {
        @content;
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Font Styless & Fluid Type */
/****************************************************************************************************/

// Fluid Typography (allows you to set max/min sizes at max/min screen sizes).
// Example usage:
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;
@include fluid-type($min_width, $max_width, $min_font, $max_font);
*/
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

// Adds a fake stroke using text-shadow
@mixin fakeTextStroke($color: black, $offset: 1px) {
    text-shadow: -#{$offset} -#{$offset} 0 $color, #{$offset} -#{$offset} 0 $color, -#{$offset} #{$offset} 0 $color, #{$offset} #{$offset} 0 $color;
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Grid Mixins */
/****************************************************************************************************/

// GRID MIXINS
@mixin col($Columns: 4, $gridGutter: 1rem) {
    flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin-left: ($gridGutter/2);
    margin-right: ($gridGutter/2);
    vertical-align: top;

    @if ($Columns == "auto" or $Columns == auto) { // use default width of items instead of column count
        flex: 0 1 auto;
        width: auto;
    }
    @else {
        @if $gridGutter == 0 {
            /*width: calc((1 / #{$Columns}) * 100%);*/
            flex-basis: calc((1 / #{$Columns}) * 100%);
        }
        @else {
            /*width: calc((1 / #{$Columns}) * 100% - #{$gridGutter});*/
            flex-basis: calc((1 / #{$Columns}) * 100% - #{$gridGutter});
        }
    }
}

@mixin grid($Columns: 4, $gridGutter: 1rem, $colSelector: ".col") {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: (-$gridGutter/2);
    margin-right: (-$gridGutter/2);
    box-sizing: border-box;

    > #{unquote($colSelector)} {
        @include col($Columns, $gridGutter);
    }
}

@mixin gutter($gridGutter: 1rem, $colSelector: ".col") { // add this to grids NOT columns in order to adjust the gutter width. DOES NOT affect column width.
    margin: 0 (-$gridGutter/2);

    > #{unquote($colSelector)} {
        margin: 0 ($gridGutter/2);
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Size & Position */
/****************************************************************************************************/

@mixin centerer($horizontal: true, $vertical: true) {
    position: absolute;

    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    }
    @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

@mixin hugParent {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
}

@mixin aspectRatioBox($ratio: 1 1, $selector: "") {
    &:before { /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
        float: left;
        width: 1px;
        height: 0;
        content: "";
        margin-left: -1px;
        padding-top: percentage(nth($ratio, 2) / nth($ratio, 1));
    }

    &:after { /* to clear float */
        content: "";
        display: table;
        clear: both;
    }

    @if($selector != "") { // use this if you want to force a child element to hug its parents dimensions (such as an iframe/image/video)
        $selector: unquote($selector);

        #{$selector} {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Overlays & Gradients */
/****************************************************************************************************/
@mixin gradientOverlay($bgColor: #000, $zIndex: 1) {
    position: relative;

    &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: $zIndex;
        position: absolute;
        background: linear-gradient(90deg, rgba($bgColor,1) 0%, rgba($bgColor,1) 45%, rgba($bgColor,0.8) 55%, rgba(#fff,0.1) 100%);
        transition: $transition;
    }

    > * {
        position: relative;
        z-index: $zIndex + 1;
    }
}

@mixin gradientBackground($bgColor: #000, $zIndex: 1) {
    position: relative;

    &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: $zIndex;
        position: absolute;
        background: radial-gradient(circle, rgba($bgColor,1) 0%, rgba(#ddd,1) 50%, rgba($bgColor,1) 100%);
        transition: $transition;
    }

    > * {
        position: relative;
        z-index: $zIndex + 1;
    }
}


@mixin bgImgWrapper($paddingBottom: $ratio3x2, $backgroundPosition: center) {
    .bgImgWrapper {
        padding-bottom: $paddingBottom;
        position: relative;

        > * {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: $backgroundPosition;
        }
    }
}

@mixin overlay($bgColor: #000, $bgOpacity: 0.6, $bgHoverOpacity: 0.6, $zIndex: 1) {
    position: relative;

    &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: $zIndex;
        position: absolute;
        background: rgba($bgColor, $bgOpacity);
        transition: $transition;
    }

    > * {
        position: relative;
        z-index: $zIndex + 1;
    }

    &:hover {
        &:after {
            background: rgba($bgColor, $bgHoverOpacity);
        }
    }
}

// `scrim` smoothing
/*background-image: linear-gradient(120deg, scrim-gradient($primaryColour));*/
@function scrim-gradient($color: #000, $opacity: 1) {
    $scrim-gradient: (
    // alpha: stop
    1: 0%, 0.738: 19%, 0.541: 34%, 0.382: 47%, 0.278: 56.5%, 0.194: 65%, 0.126: 73%, 0.075: 80.2%, 0.042: 86.1%, 0.021: 91%, 0.008: 95.2%, 0.002: 98.2%, 0: 100% );

    @if type-of($color) != color {
        @return null;
    }

    $rgba: "#{red($color)},#{green($color)},#{blue($color)}";
    $color-stops: ();

    @each $key, $value in $scrim-gradient {
        $color-stops: append($color-stops, unquote("rgba(#{$rgba}, #{$key * $opacity}) #{$value}"), "comma");
    }

    @return $color-stops;
}

// `easeOutSine` smoothing
@function easeOutSine-gradient($color: #000, $opacity: 1) {
    $easeOutSine-gradient: (
    // alpha: stop
    1: 0%,0.917: 5.3%, 0.834: 10.6%, 0.753: 15.9%, 0.672: 21.3%, 0.591: 26.8%, 0.511: 32.5%, 0.433: 38.4%, 0.357: 44.5%, 0.283: 50.9%, 0.213: 57.7%, 0.147: 65%, 0.089: 72.9%, 0.042: 81.4%, 0.011: 90.6%, 0: 100% );

    @if type-of($color) != color {
        @return null;
    }

    $rgba: "#{red($color)},#{green($color)},#{blue($color)}";
    $color-stops: ();

    @each $key, $value in $easeOutSine-gradient {
        $color-stops: append($color-stops, unquote("rgba(#{$rgba}, #{$key * $opacity}) #{$value}"), "comma");
    }

    @return $color-stops;
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Whitespace  */
/* You shouldn't ever need to use this directly - it's just used to generate our whitespace classes */
/* based on: https://styleguide.pivotal.io/modifiers/whitespace/#classes */
/****************************************************************************************************/

$whitespace-keys: (p padding, m margin, a '', v -top -bottom, h -left -right, t -top, r -right, b -bottom, l -left);

@function expand-whitespace-key($k) {
    @each $pair in $whitespace-keys {
        $short: nth($pair, 1);
        $long: nth($pair, 2);
        // v and h need to pass in 2 parameters
        @if (length($pair) == 3) {
            $long: (nth($pair, 2) nth($pair, 3))
        }

        @if $short == $k {
            @return $long;
        }
    }

    @warn "Couldn't expand #{$k}";
    @return $k;
}

$types: (p, m); // padding,margin
$locations: (a, v, h, t, r, b, l); // all,top,right,bottom,left,horizontal,vertical
$tShirtSizes: (n, xs, s, m, l, xl, xxl, xxxl, xxxxl);

@mixin whitespace($units: ()) {
    $units: join(0, $units);

    @for $i from 1 through length($units) {
        $tShirtSize: nth($tShirtSizes, $i);
        $size: nth($units, $i);

        @each $location in $locations {
            @each $type in $types {
                .#{$type}#{$location}#{$tShirtSize} {
                    @each $whitespace-location in expand-whitespace-key($location) {
                        #{expand-whitespace-key($type)}#{$whitespace-location}: $size !important;
                    }
                }
            }
        }
    }
}

/* #endregion */
/****************************************************************************************************/


/****************************************************************************************************/
/* #region Modifiers  */
/* You shouldn't ever need to use this directly - it's just used to generate our subheader classes */
/* Based content https://frontstuff.io/generate-all-your-utility-classes-with-sass-maps            */
/****************************************************************************************************/
@mixin modifiers($map, $attribute, $prefix: '-', $separator: '-', $base: 'base') {
    @each $key, $value in $map {
        &#{if($key != $base, #{$prefix}#{$key}, '')} {
            @if type-of($value) == 'map' {
                @include modifiers($value, $attribute, $separator);
            }
            @else {
                #{$attribute}: $value !important;
            }
        }
    }
}

/* #endregion */
/****************************************************************************************************/


/****************************************************************************************************/
/* #region Misc */
/****************************************************************************************************/

@mixin forceColour($colorPassed: #fff) {
    color: $colorPassed;

    * {
        color: inherit;
    }
}

@mixin separator {
    width: 2px;
    height: 0.9rem;
    display: inline-block;
    margin: 0 0.6rem;
    padding: 0;
    background-color: white;
}

@mixin sr-only {
    clip: rect(0,0,0,0);
    width: 1px;
    height: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    background-color: #fff;
    color: #000;
}

/* shared outline styles for focus */
@mixin outline {
    outline: $outline;
    outline-offset: $outline_offset;
}

@mixin smallcaps {
    font-family: $secondaryFont;
    font-weight: $fontWeightNormal;
    text-transform: uppercase;
    letter-spacing: $letterSpacingSideTitle;
}

@mixin headingStyles {
    font-style: normal;
    font-family: $secondaryFont;
    font-weight: $fontWeightMedium;
    line-height: $lineHeightHeadings;
}

/* #endregion */
/****************************************************************************************************/
