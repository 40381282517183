﻿%columnNoPadding {
    .ui.grid>.column:not(.row) {
        padding: 0 !important;
    }
}

%clearfix {
    display: block;
    &:after {
         visibility: hidden;
         display: block;
         font-size: 0;
         content: " ";
         clear: both;
         height: 0;
    }
}

%roundImage {
    border-radius: 50%;
    border: solid 1px $primaryColour;
    padding: 5px;
    background-clip: content-box !important; /* support: IE9+ */
}

%unorderedList {
    list-style: disc;
    padding-left: 35px;
    margin-bottom: 25px;
    li {
        padding-left: 0;
        margin-bottom: 3px;
        background-size: 12px;
        list-style-image: url(/CFImages/icon-arrow.svg);
    }
}

%brandedHeading {
    position:relative;
    &:after {
        content: " ";
        /*position:absolute;*/
        color: $secondaryColour;
        font-size: 25%;
        margin-left: 0.5em;
    }
}

%dropShadow {
    box-shadow: 3px 5px 10px 1px rgba($greyDark,0.6);
    padding: 0.5em;
}

/* Force text to wrap with hyphen if to long (example long email) */
%forceWordBreak {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
    white-space:unset;
}

%gradientOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0,0,0,0.85) 0%, rgba(2,2,2,0.85) 1%, rgba(255,255,255,0) 100%); /* w3c */
}

%grayscale { // This works in all but IE 10/11. IE 6-9 work great. 
    filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

%noUserSelect {
    user-select: none !important;
    outline: none !important;
    pointer-events: none;

    &:active, &:focus, &:visited, &:hover {
        user-select: none !important;
        outline: none !important;
    }
}

%fontAwesomeBase {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

%fontAwesomeSolid {
    @extend %fontAwesomeBase;
    font-weight: $fontWeightBold;
}

%fontAwesomeLight {
    @extend %fontAwesomeBase;
    font-weight: $fontWeightLight
}

%fontAwesomeRegular {
    @extend %fontAwesomeBase;
    font-weight: $fontWeightNormal;
}

%borderBottomShort {
    position: relative;
    &:after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 3rem;
        height: 1px;
        background-color: $primaryColour;
    }
}

%borderBottomShortCentred {
    position: relative;

    &:after {
        content: ' ';
        position: absolute;
        left: calc(50% - 1.5rem);
        bottom: 0;
        width: 3rem; // updated to match the width of "Partner" on the Team CardView
        height: 1px;
        background-color: $primaryColour;
    }
}

%fullWidthPadding {
    padding-left: 5rem;
    padding-right: 5rem;
    /*        @media(max-width:$XL_Max) {
            padding-left: $desktopPadding;
            padding-right: $desktopPadding;
        }*/

    @media(max-width:$XL_Max) {
        padding-left: $largeDesktopPadding;
        padding-right: $largeDesktopPadding;
    }

    @media(max-width:$L_Max) {
        padding-left: $desktopPadding;
        padding-right: $desktopPadding;
    }

    @media(max-width:$M_Max) {
        padding-left: $tabletPadding;
        padding-right: $tabletPadding;
    }

    @media(max-width:$S_Max) {
        padding-left: $mobilePadding;
        padding-right: $mobilePadding;
    }

    @media(max-width:$XS_Max) {
        padding-left: $xsPadding;
        padding-right: $xsPadding;
    }
}

%fullWidthBackgroundColour {
    position: relative;
    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200vw;
        content: ' ';
        /*z-index: 1;*/
        background-color: $greyXXXLight;
        pointer-events: none;
    }
}

%doubleQuoteBefore {
    &:before { // double quote icon
        content: ' ';
        z-index: 0;
        position: absolute;
        /*            top: 45%;
            left: 40%;
            transform: translateY(-50%) translateX(-50%);*/
        top: 0;
        left: 0;
        /*transform: translateY(-20%) translateX(0);*/
        background-image: url(/CFImages/double-quote.svg);
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        height: 12rem;
        width: 12rem;
        filter: invert(1); // turn black icon to white
        opacity: 0.15;
    }
}

%hugParent {
    inset: 0;
    display: block;
    position: absolute;
}

%roundedEdges {
    background-color: rgba($greyXXXLight, .8);
    padding: .5rem;
    border-radius: $borderRadiusSmall;
}