﻿@import '_variables';
/* these need to be here for intellisense and go-to-definition to work */
/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />
/// <reference path="_placeholders.scss" />
/// <reference path="_grids.scss" />

/****************************************************************************************************/
/* #region Document */
/****************************************************************************************************/

::selection {
    background: $selectionColor;
    color: white;
}

@include placeholder {
    color: $greyDark;
    text-transform: none;
}

* {
    box-sizing: border-box;
}

:root {
    --scrollbar-width: 16px;
    --header-height: $headerHeight;
    --card-width: #{$cardWidth};
    --column-count: 3;
    --card-gap: #{$cardGap};
    color-scheme: normal !important; // disable dark mode to prevent issues (until addressed)
    /* For Images */
    --image-width: 0;
    --image-height: 0;
    --image-aspectratio: 1.5;
}

html {
    min-height: auto;
    font-style: normal;
    font-family: $primaryFont;
    font-weight: $fontWeightNormal;
    line-height: $lineHeight;
    @include fluid-type($L_Min, $XL_Min, 16px, 16px); // base font size ranges from 16-20px based on screen width
    scroll-behavior: smooth; //use to add smooth scrolling to jump menus with anchors
}

body {
    min-height: 100vh;
    overflow-x: hidden; // stops mobile menu from adding scrollbars
    overflow-y: auto;
    color: $bodyFontColour;
    font-style: normal;
    font-family: $primaryFont;
    font-weight: $fontWeightNormal;
    background-color: white;
}

/* Custom Scrollbar Styles (only works in the latest browsers) */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($greyXXXLight,0.5);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: $greyXXXLight;
}

::-webkit-scrollbar-thumb {
    background-color: $greyXXLight;
    border: 2px solid $greyXXLight;
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Focus Styles */
/****************************************************************************************************/

* {
    outline-offset: $outline_offset;
}

*:focus {
    /* simple focus styles (for older browsers without support for :focus-visible */
    outline: $outline;
}

*:focus:not(:focus-visible) {
    /* undo all the above focused button styles */
    outline: none;
}

*:focus-visible {
    /* focus styles for modern browsers */
    outline: $outline;
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Text-level Semantics */
/****************************************************************************************************/

p {
    margin: 0 0 1rem 0;
    padding: 0;
    font-size: 1em;
    font-style: normal;
    line-height: $lineHeight;
}

strong, b {
    /*font-weight: $fontWeightSemiBold;*/
    font-weight: $fontWeightBold;
    font-style: normal;
}

em, i {
    font-style: italic;
}

sup {
    vertical-align: super;
    font-size: 50%;
    font-style: normal;
}

sub {
    vertical-align: sub;
    font-size: 50%;
    font-style: normal;
}

blockquote {
    width: 100%;
    max-width: 100%;
    margin: 2rem auto;
    padding: 3rem 10%;
    position: relative;
    border: none;
    color: inherit;
    text-align: left;
    font-size: 1.2em;
    background-color: $greyXXLight;

    p {
        color: inherit;
        font-weight: $fontWeightBold;
        font-style: normal;
    }

    > :last-child {
        margin-bottom: 0;
    }

    cite {
        font-style: italic;
        font-family: $secondaryFont;
    }

    footer {
    }
}

a {
    color: $secondaryColour;
    transition: $transition;
    text-decoration: none;
}

a:link {
}

a:visited {
}

a:hover {
    color: $secondaryColour;
    text-decoration: underline;
}

a:active {
    color: $primaryColour;
    text-decoration: none;
}

hr {
    border: none;
    height: 1px;
    margin: 2rem 0;
    background-color: $greyXXLight;
}

li {
    list-style: none;
    font-size: 1em;
    font-style: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        list-style: none;
    }

    ul, ol {
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Headings */
/****************************************************************************************************/

span {
    &.seperator {
        padding: 0 7px;
        color: $greyLight;
    }
}

/* Headings */
h1, h2, h3, h4, h5, h6, .h {
    @include headingStyles;
    color: $secondaryColour;
    margin: 1.4rem 0 1.38rem;
    padding: 0;
    display: block;

    &.centered {
        text-align: center;
    }
}

// remove height from consecutive headings
:is(h1, h2, h3, h4, h5, h6, .h) {
    + :is(h1, h2, h3, h4, h5, h6, .h) {
        margin-top: 0;
    }
}



// relative heading sizes generated using https://type-scale.com/ (Major Second)
h1 {
    color: $secondaryColour;
    font-size: 3.65rem;
}

h2 {
    font-size: 2.402rem;
    font-size: clamp(1.6rem, 1.244rem + 1.4238vw, 2.4rem);
}

h3 {
    font-size: 1.824rem;
}

h4 {
    font-size: 1.566rem;
    color: $primaryColour;
}

h5 {
    font-size: 1.325rem;
}

h6 {
    font-size: 1rem;
}

/* fake headings (turn any element into a heading, basically). These need to match the above. */
.h1 {
    font-size: 2.488rem !important;
}

.h2 {
    font-size: 1.602rem !important;
}

.h3 {
    font-size: 1.424rem !important;
}


.h4 {
    font-size: 1.266rem !important;
}

.h5 {
    font-size: 1.125rem !important;
}

.h6 {
    font-size: 1rem !important;
}


/* #endregion */
/****************************************************************************************************/



/****************************************************************************************************/
/* #region Buttons */
/****************************************************************************************************/

:is(.button, button),
.cmsContent :is(.button, button) {
    background: $primaryColour;
    color: #fff;
    border-radius: 50vh;
    display: inline-flex;
    padding: .75rem 1.25rem;
    justify-content: center;
    align-items: center;
    /*gap: 0.625rem;*/
    gap: 0.8rem;
    transition: $transition;
    font-size: .9rem;
    border: none;
    cursor: pointer;
    margin-top: 1.4em;
    position: relative;
    z-index: 1;

    &:hover, &:focus {
        color: #fff;
        background: $secondaryColour;
        text-decoration: none;
    }

    &:active {
        color: white;
        background: $secondaryColour;
        text-decoration: none;

        &:after {
            filter: brightness(0) invert(1);
        }
    }

    &.arrow {
        &:after {
            content: ' ';
            width: 1em;
            height: 1em;
            display: inline-block;
            transition: $transition;
            background: url(/CFImages/Icons/arrow-right.svg) no-repeat center;
            background-size: 100% auto;
        }
    }

    &.circle {
        padding: .5rem;
        margin-top: 0;

        &:after {
            filter: brightness(0) invert(1);
        }

        &:hover {
            transform: rotate(-45deg);
        }
    }

    &.outline {
        color: white;
        background: transparent;
        border: solid 1px #fff;

        &.reverse {
            color: $primaryColour;
            border-color: $primaryColour;

            &:after {
                filter: invert(65%) sepia(100%) hue-rotate(297deg) saturate(144.5)
            }

            &:hover:after {
                filter: brightness(0) invert(1);
            }
        }

        &:after {
            filter: brightness(0) invert(1);
        }

        svg {
            path {
                fill: currentColor;
            }
        }

        &:hover, &:focus, &:active {
            color: #fff;
            background: $primaryColourLight;
        }
    }

    &.transparent {
        color: white;
        background: transparent;

        svg {
            path {
                fill: currentColor;
            }
        }

        &:hover, &:focus {
            color: $secondaryColour;
            background: transparent;
        }

        &:active {
            color: $secondaryColourDark;
            background: transparent;
        }
    }
    // dark variant (shown on .light sections)
    .light & {
        background: $primaryColourDark;
        color: white;

        &:after {
            filter: brightness(0) invert(1);
        }

        &:hover, &:focus {
            color: $primaryColourDark;
            background: white;
            text-decoration: none;

            &:after {
                filter: none;
            }
        }

        &:active {
            color: white;
            background: $secondaryColour;

            &:after {
                filter: brightness(0) invert(1);
            }
        }
    }

    &.large {
        width: 100%;
        justify-content: flex-start;
        border-radius: $borderRadius;
        font-size: 1.2rem;
        margin-top: 0;
        padding: 1.5rem 3.5rem 1.5rem 1.5rem;
        min-height: 160px;

        &:after {
            position: absolute;
            right: 1.5rem;
            filter: brightness(0) invert(1);
        }
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Hyperlinks + Special Links */
/****************************************************************************************************/

/* Special Links */
a[href^="mailto"] {
    @extend %forceWordBreak;
}


/* Non-link elements with onclick functionality */
.clickable {
    cursor: pointer;
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Images */
/****************************************************************************************************/
img {
    width: auto;
    height: auto;
}

/* As a NATIVE picture img */
.imgWrapper {
    width: 100%;

    img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        aspect-ratio: auto;
        object-fit: cover;
    }
    /* if wrapped in hyperlink */
    a {
        img {
            max-width: 100%;
        }
    }
}

/* As BACKGROUND image */
.bgImgWrapper {
    @include aspectRatioBox(16 9, ".bgImg");
    max-width: 100%;
    z-index: 0;
    overflow: hidden;
    position: relative;

    .bgImg {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    a {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.coverImg {
    @extend %hugParent;
}

/* As fullscreenimage */
.backstretch {
    .overlay {
        inset: 0;
        position: absolute;
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/*#region TYPOGRAPHY CMS Content Styles */
/****************************************************************************************************/
// CMS CONTENT STYLES
.cmsContent {
    color: $bodyFontColour;
    font-size: 1em;
    line-height: $lineHeight;

    img {
        max-width: 100%;
    }

    em, i {
        font-style: italic;
    }
    /* Headings */
    h1, h2, h3, h4, h5, h6, .h {
        color: $secondaryColour;
        font-weight: 700;

        &:first-child {
            margin-top: 0;
        }
    }

    h5 {
        line-height: 1.4;
        color: $primaryColour;
    }
    /* Links */
    a {
        color: $secondaryColour;
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }

        &:active {
            color: darken($secondaryColour, 10%);
            text-decoration: underline;
        }
    }
    // external links
    /*    a[1="_blank"]:not(.button):after {
        content: " ";
        position: relative;
        display: inline-block;
        font-size: .6em;
        padding-left: .5rem;
    }*/
    .socialLinks a[target="_blank"]:after {
        content: none;
    }
    /* Paragraphs */
    p {
        line-height: $lineHeight;
    }
    /* SHARED list styles */
    ul,
    ol {
        margin: 1rem 0 1rem 1rem;
        /* SHARED list item styles */
        li {
            margin: 0 0 0.8em;
            position: relative;
            padding: 0 0 0 1.75rem;
        }
        /* SHARED nested list styles */
        ol,
        ul {
            margin: 0.4rem 0;

            > li {
                background: none;


                &:before {
                    color: $bodyFontColour;
                }
            }
        }
        /* Special lists:   Alpha & Roman Numerals */
        // just add classes "roman" or "alpha" to <ol> or <ul> in content editor
        &.roman {
            > li {
                padding-left: 2rem;

                &:before {
                    content: counter(customListCounter, lower-roman) ".";
                }
            }
        }

        &.alpha, &.lettered {
            > li {
                &:before {
                    content: counter(customListCounter, lower-alpha) ".";
                }
            }
        }

        &.upper,
        &.uppercase { // capital letters
            > li {
                &:before {
                    text-transform: uppercase;
                }
            }
        }
        // reset to NON LIST STYLES
        &.reset {
            li {
                padding-left: 0;

                &:before {
                    display: none;
                }
            }
        }
    }
    /* Unordered Lists Styles ONLY */
    /* Make sure what you're adding belongs here, and not in the SHARED list styles above */
    ul {
        list-style-type: none;

        > li {
            list-style-type: none;
            font-size: 1em;
            position: relative;
            /* bullet icon */
            &:before {
                content: ' ';
                top: initial;
                left: 0;
                width: 1em;
                height: 1.4em; // needs to match the height of a single line of the li (in order to keep it centered with the text)
                color: $bodyFontColour;
                content: "";
                display: block;
                position: absolute;
                line-height: inherit;
                background-image: url(/CFImages/Icons/bullet-square-solid.svg);
                background-size: 0.4em auto;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
    /* Ordered Lists */
    /* Make sure what you're adding belongs here, and not in the SHARED list styles above */
    ol {
        counter-reset: customListCounter 0;
        list-style-position: outside;

        > li {
            list-style-type: none;
            list-style-position: inside;
            counter-increment: customListCounter;

            &:before {
                top: initial;
                left: 0.4rem;
                content: counter(customListCounter) ".";
                color: $bodyFontColour;
                position: absolute;
                font-weight: $fontWeightNormal;
            }
        }
    }
    /* Misc content elements */
    address {
        margin: 0.5rem 0 1rem;
    }

    mark {
        color: white;
        background-color: $selectionColor;
    }

    pre {
        white-space: pre-wrap;
        background-color: #efefef;
        padding: 2rem;
    }

    &.highlight > p:nth-of-type(1) {
        font-size: 1.266rem;
        font-weight: 500;
        line-height: 1.2;
        margin: 0 0 1.38rem;
        color: $primaryColour;
    }

    &.highlightFirstElement > :first-child {
        @extend .h;
        @extend .h3;
        margin: 0 0 1.38rem;
        color: $primaryColour;
        line-height: 1.4;

        > * {
            font: inherit;
            line-height: inherit;
        }
    }
    /* last child selector */
    /*
    > :last-child {
    }
    */
}

// Typically should be paired with .bgDark (for the backgroundColour)
.dark, .dark .cmsContent {
    color: white;

    ::selection {
        background: $selectionColourDark;
        color: white;
    }

    h1, h2, h3, h4, h5, h6, p, ul li, address {
        color: white;
    }

    a {
        color: #fff;
    }

    a:link, a:visited {
        color: white;
    }

    a:hover, a:active, a:focus {
        color: #fff;
        text-decoration: underline;
    }

    a.button {
        &:hover, &:active, &:focus {
            color: white
        }
    }
    /* SHARED list styles */
    ul,
    ol {
        /* SHARED nested list styles */
        ol,
        ul {
            > li {
                &:before {
                    color: white;
                }
            }
        }
    }
    /* Unordered Lists Styles ONLY */
    /* Make sure what you're adding belongs here, and not in the SHARED list styles above */
    ul {
        > li {
            /* bullet icon */
            &:before {
                color: white;
                background-image: url(/CFImages/Icons/bullet-square-solid-white.svg);
            }
        }
    }
    /* Ordered Lists */
    /* Make sure what you're adding belongs here, and not in the SHARED list styles above */
    ol {
        > li {
            &:before {
                color: white;
            }
        }
    }

    mark {
        color: white;
        background-color: $selectionColourDark;
    }

    pre {
        background-color: $primaryColourDark;
        color: white;
    }

    blockquote {
        background-color: $primaryColourDark;
        color: white;

        p {
            color: white;
        }
    }
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Icons / FontAwesome / Kendo Icons */
/****************************************************************************************************/
/* A trick for prefetching icons we know we need to prevent the flash-of-unstyled-icon on hover */
body::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(/CFImages/Icons/chevron-down.svg) url(/CFImages/Icons/chevron-up.svg) url(/CFImages/Icons/audio.svg) url(/CFImages/Icons/plus.svg) url(/CFImages/Icons/minus.svg);
}
/* Any and all FontAwesome icons */
[class^='fa-'], [class*=' fa-'] {
    color: inherit;
}
/* shared Kendo widget icon styles (works with navigation css file) */
.k-icon {
    // NOTE: for better ux on touch devices, keep the button fairly large and instead modify the bg image size
    width: 3rem;
    height: 3rem;

    &:before {
        content: ' ';
        @include hugParent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 0.6rem;
    }
    /* Expand / Collapse Arrows */
    &.k-menu-expand-arrow:before,
    &.k-i-expand:before {
        background-image: url(/CFImages/Icons/plus.svg);
        transition: $transition;

        .k-state-active &, .k-state-hover & {
            background-image: url(/CFImages/Icons/minus.svg);
        }
    }

    &.k-i-collapse:before {
        background-image: url(/CFImages/Icons/minus.svg);

        .k-state-active & {
            background-image: url(/CFImages/Icons/plus-white.svg);
        }
    }
}

/* selected element icon (needs to be white because dark background */
li.k-item.selected {
    > div .k-icon:first-of-type {
        &.k-i-expand:before {
            background-image: url(/CFImages/Icons/plus-white.svg);
        }

        &.k-i-collapse:before {
            background-image: url(/CFImages/Icons/minus-white.svg);
        }
    }
}
/* Custom icons element with background svgs*/
.icon {
    display: inline-block;
    font-size: 1em;

    &:before {
        content: ' ';
        width: 1.4em;
        height: 1.4em;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &.audio {
        &:before {
            background-image: url(/CFImages/Icons/audio.svg);
        }
    }

    &.print {
        &:before {
            background-image: url(/CFImages/Icons/print.svg);
        }
    }

    &.bookmark {
        &:before {
            background-image: url(/CFImages/Icons/bookmark.svg);
        }
    }

    &.share {
        &:before {
            background-image: url(/CFImages/Icons/share.svg);
        }
    }

    &.vcard {
        &:before {
            background-image: url(/CFImages/Icons/vcard.svg);
        }
    }

    &.video {
        &:before {
            background-image: url(/CFImages/Icons/video.svg);
        }
    }
}
/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Skip-to-Content + Sticky Links */
/****************************************************************************************************/
#skiptocontent {
    a {
        left: -999px;
        position: absolute;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
        z-index: -999;

        &:focus, &:active {
            top: auto;
            left: auto;
            width: 30%;
            height: auto;
            z-index: 999;
            overflow: auto;
            margin: 10px 35%;
            padding: 5px;
            color: white;
            font-size: 1.2rem;
            text-align: center;
            border: 4px solid $selectionColor;
            border-radius: 15px;
            background-color: black;
        }
    }
}

/* #endregion */
/**

/****************************************************************************************************/
/* #region Layout / Structure */
/****************************************************************************************************/
/*Sticky Footer Items */
body {
    display: flex;
    flex-direction: column;
}

#wrapper {
    overflow: hidden; // hide unwanted scrollbars
    height: auto !important;
    flex: 1 0 auto;

    .k-ie11 & {
        flex: inherit;
    }
}

.pageWrapper {
    position: relative;
    transition: $transition;
    left: 0;
}

.sfPageEditor {
    .wrapper {
        > * {
            opacity: 1 !important;
            transform: translatey(0) !important;
        }
    }
}

.wrapper {
    //padding: 2rem 0rem 5rem;
    > * {
        opacity: 0;
        transform: translatey(10%);
        transition: opacity 1s, transform .5s;
    }

    &.inViewport {
        > * {
            opacity: 1;
            transform: translatey(0);
        }

        h2 {
            &:after {
                width: 3rem;
            }
        }
    }
}

$wrapperPadding: 5rem 0rem 5rem;

.wrapperPadding {
    padding: $wrapperPadding;
}

.primaryWrapper {
    background-color: $primaryColour;

    &.wrapper {
        padding: $wrapperPadding;
    }
}

.secondaryWrapper {
    background-color: $secondaryColour;

    &.wrapper {
        padding: $wrapperPadding;
    }
}

.darkestWrapper {
    background-color: $tertiaryColour;

    &.wrapper {
        padding: $wrapperPadding;
    }
}

.photoWrapper {
    position: relative;

    .cover img {
        position: absolute;
        bottom: 0;
        z-index: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.wavyWrapper {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background: url(/images/default-source/default-album/wavy-lines.svg) no-repeat 0 40px;
        height: 100%;
        width: 100%;
        opacity: .3;
        z-index: 0;
    }

    &.reverse {
        &:after {
            transform: scaleX(-1);
            z-index: 0;
        }
    }
}

.greyWrapper {
    background-color: $greyXXXLight;

    &.wrapper {
        padding: $wrapperPadding;
    }
}

.borderRadiusWrapper {
    border-radius: $borderRadius;
    overflow: hidden;
}

.borderRadiusGreyWrapper {
    background-color: $greyXXXLight;
    border-radius: $borderRadius;
    padding: 4rem;
    margin-bottom: 2rem;
}
/*.borderRadiusDarkGreyWrapper {
    background-color: $secondaryColour;
    border-radius: $borderRadius;
    padding: 4rem;
}*/
.flexWrapper {
    display: grid;
}

.threeOne {
    gap: 2rem;
    grid-template-columns: 3fr 1fr;

    &.flexReverse {
        .listViewTitle {
            order: 2;
            grid-area: 1 / 2 / 2 / 3;
        }

        .viewAllMoreWrapper {
            grid-area: 1 / 2 / 2 / 3;
            text-align: left;
            margin-top: 4rem;
            z-index: 2;

            @media only screen and (max-width: 1320px) { // Because I'm overlapping grid items uggh.
                margin-top: 7rem;
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Containers */
/****************************************************************************************************/
.container {
    width: $container;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    // Control whether containers are always inset from the window.
    // If false, you'll need to always make sure to provide a top-level .container.full for padding
    $containers_always_inset: true;

    @if($containers_always_inset) {
        max-width: calc(100vw - (#{$fullWidthPadding} * 2) - var(--scrollbar-width));

        @media(max-width:$L_Max) {
            max-width: calc(100vw - (#{$desktopPadding} * 2) - var(--scrollbar-width));
        }

        @media(max-width:$M_Max) {
            max-width: calc(100vw - (#{$tabletPadding} * 2) - var(--scrollbar-width));
        }

        @media(max-width:$S_Max) {
            max-width: calc(100vw - (#{$mobilePadding} * 2) - var(--scrollbar-width));
        }

        @media(max-width:$XS_Max) {
            max-width: calc(100vw - (#{$xsPadding} * 2) - var(--scrollbar-width));
        }
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    &.wide {
        width: calc(#{$container_wide});
    }

    &.narrow {
        width: calc(#{$container_narrow});
    }
    // spans the full width of the parent, but adds wide horizontal gutters/padding
    &.full {
        width: 100%;
        max-width: 100%;
        @extend %fullWidthPadding;

        > .container.full:not(.force) {
            padding-left: 0;
            padding-right: 0;
        }

        &.force {
            width: calc(100vw - var(--scrollbar-width));
        }
    }

    &.edge {
        width: 100vw;
        max-width: calc(100vw); // fallback
        max-width: calc(100vw - var(--scrollbar-width));
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        margin-left: 0;
        margin-right: 0;

        &.full {
            width: 100vw;
            max-width: 100vw;
        }

        *.container.full {
            @extend %fullWidthPadding;
        }
    }

    &.force {
        max-width: 100vw;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        margin-left: 0;
        margin-right: 0;
    }
}
/* pad an element, pushing content in to align with the main container - this needs to be updated to match global padding settings*/
$known_breakpoint: 1574px; // for now, this is just set manually to a magic number. Whatever the width is when we need to switch to global padding.
.padLeft {
    padding-left: calc((100vw - #{$container}) / 2) !important; // fallback for browsers that don't support custom variables
    padding-left: calc((100vw - #{$container} - var(--scrollbar-width)) / 2) !important;

    @media(max-width: $known_breakpoint) {
        padding-left: $largeDesktopPadding !important;
    }

    @media(max-width:$L_Max) {
        padding-left: $desktopPadding !important;
    }

    @media(max-width:$M_Max) {
        padding-left: $tabletPadding !important;
    }

    @media(max-width:$S_Max) {
        padding-left: $mobilePadding !important;
    }

    @media(max-width:$XS_Max) {
        padding-left: $xsPadding !important;
    }
}

.padRight {
    padding-right: calc((100vw - #{$container}) / 2) !important; // fallback for browsers that don't support custom variables
    padding-right: calc((100vw - #{$container} - var(--scrollbar-width)) / 2) !important;

    @media(max-width: $known_breakpoint) {
        padding-right: $largeDesktopPadding !important;
    }

    @media(max-width:$L_Max) {
        padding-right: $desktopPadding !important;
    }

    @media(max-width:$M_Max) {
        padding-right: $tabletPadding !important;
    }

    @media(max-width:$S_Max) {
        padding-right: $mobilePadding !important;
    }

    @media(max-width:$XS_Max) {
        padding-right: $xsPadding !important;
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/*#region Site Header */
/****************************************************************************************************/
#header {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    //position: absolute;
    transition: $transition;
    /* 
    &.up {
        position: fixed;
        animation: header-slide-in 300ms ease;
    }*/

    .searchHiddenWrapper {
        /* refer to "region Site Search" styles below */
        display: none;
    }

    .topHeader {
        display: none;
    }

    .header {
        padding: 0;
        z-index: 100;
        padding: 1.5rem;
        position: relative;
        //inside topHeaderGridLeft
        .logo {
            img {
                width: 100%;
                height: auto;
                max-width: 15rem;
                min-width: 160px;
            }
        }

        .menu {
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: flex-end;
        }

        .socialWrapper {
            display: flex;
            flex-direction: row-reverse;
            gap: 1rem;
            align-items: flex-start;
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/*#region Page Topper (Banner) */
/****************************************************************************************************/
/* Usually nested in the topper */
.topperSection { // For detail pages without taglines
    position: relative;
    margin: 0;
    text-align: left;

    .meta {
        text-transform: uppercase;
        font-size: 0.8rem;

        .separator {
            margin: 0 0.4rem;
        }
    }

    .contentTypeLabels {
        font-size: inherit;
    }

    hr {
        margin: 2rem 0 0;
    }

    .topperContent {
        padding: 3rem 0 1rem;
        text-align: left;

        .topperTagLine {
            text-align: center;
        }
    }
    // If has an image
    &:has(.topperImage) {
        overflow: hidden;
        min-height: 500px;
        border-radius: $borderRadius;
        display: flex;
        flex-direction: column-reverse;

        .topperImage {
            position: absolute;
            height: 100%;
            width: 100%;

            .imgWrapper {
                height: 100%;
                width: 100%;
            }
        }

        .topperContent {
            padding: 0;

            h1 {
                margin: 0;
                position: relative;
                display: inline-block;
                margin-left: -1rem;

                &:before {
                    content: "";
                    position: absolute;
                    top: -82px;
                    left: 16px;
                    height: 76px;
                    width: 52px;
                    border-bottom-left-radius: 50%;
                    background: transparent;
                    box-shadow: -10px 17px 0px 0px #fff;
                    z-index: 2;
                }
                /* Casues issue when */
                /*&:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: -52px;
                    height: 76px;
                    width: 52px;
                    border-bottom-left-radius: 50%;
                    background: transparent;
                    box-shadow: -10px 36px 0px 0px #fff;
                }*/
            }

            .meta {
                color: $tertiaryColour;
            }
        }
    }
    // If no image, do some other styles
    &:not(:has(.topperImage)) {
        //background-color: #000;
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/*#region Site Main Content */
/****************************************************************************************************/
main {
    // Should match the height of the header (for fixed menu on scroll up)
    .bd {

        .cmsContent {

            img {
                border-radius: $borderRadius;
            }
        }

        .breadCrumbInner {
            padding: .75rem .5rem .75rem 1rem;
            font-size: 0.7rem;
            display: inline-block;
            background-color: $greyXXXLight;
            border-radius: 50vh;
            margin-bottom: 1rem;

            a {
                color: $greyDark;
                text-decoration: none;
                font-weight: $fontWeightNormal;
            }

            > * {
                &:last-child {
                    color: $primaryColour;
                }

                &:not(:last-child) {
                    color: $greyDark;
                    font-weight: $fontWeightNormal;

                    &:hover,
                    &:focus {
                        color: $primaryColour;
                    }
                }
            }

            span.separator {
                margin: 0 0.6em;
            }

            span.current {
                background-color: #fff;
                border-radius: 50vh;
                padding: .5rem .75rem;
            }
        }

        .mainContent {
            //padding: 2rem 0;
            // Force Max width of image in content blocks and allow for scaling on responsive
            .sfContentBlock {
                img {
                    max-width: 100%;
                }
            }
        }
        /*        .sideContent {
            padding: 2rem 0;
        }*/
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/*#region Site Footer */
/****************************************************************************************************/
/* all footer sections */
/* NOTE USE mixin utility classes of mvxl or pvxl etc on Templates to contol padding and margins */
.siteFooter {
    position: relative;
    z-index: 1;
    background-color: $greyXXXLight;
    border-top: solid 1px $greyXXLight;

    .preFooter {


        .preFooterGrid {
            .preFooterLeft {
            }

            .preFooterRight {
            }
        }
    }

    .mainFooter {
        font-size: 80%;

        a {
            &:hover {
                color: $primaryColour;
                text-decoration: none;
            }
        }

        .mainFooterGrid {

            .mainFooterLeft {
                img {
                    max-width: 200px;
                    width: 100%;
                }
            }

            .mainFooterRight {
                .officesWrapper {
                    .offices {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        gap: inherit;
                        margin-bottom: 0;

                        .cardView.office .cardContent {
                            padding: 0;

                            h3 {
                                font-size: 1rem;
                                margin-bottom: 1rem;
                                font-weight: 700;
                            }

                            .prefixLabel {
                                margin-right: .25rem;
                            }
                        }
                    }
                }



                .mainFooterRightGrid {
                    .footerSitemap {
                    }

                    .footerSocials {
                    }
                }
            }
        }
    }

    .copyrightFooter {
        font-size: 70%;

        .container {
            border-top: solid 1px $grey;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .copyright {
        }

        .legal {
        }

        .cubicle {
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Sticky Sidebar (Scroll Nav) */
/****************************************************************************************************/
// the sticky scroll links inside the sideContent
.sticky-wrapper {
    position: relative;
}

.stickySidebar {
    padding: 0;
    position: relative;
    height: calc(100vh - 300px);
    overflow: auto;
    display: flex;
    width: 100%;
    justify-content: flex-start;

    &.stuck {
        position: fixed;
        top: 250px; // needs to match the offset value in the Waypoints/Sticky configuration
        z-index: 10;
    }

    ul.scrollingSideBar {
        padding: 1.5rem;
        width: 100%;
        background-color: rgba(white, 0.8);
        box-shadow: $boxShadow;

        li {
            position: relative;
            padding: 0.4rem 0;

            a {
                padding: 0 1.5rem 0 0;
                font-size: 1rem;
                color: $offBlack;
                text-decoration: none;
                display: block;
                width: 100%;

                &:focus,
                &:hover,
                &:active {
                    color: $offBlack;
                    padding: 0 0 0 1.5rem;
                    font-weight: $fontWeightSemiBold;
                    transition: $transition;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 0.4rem;
                        height: 0.4rem;
                        top: 50%;
                        left: 0%;
                        transform: rotate(180deg) translateX(-50%) translateY(50%);
                        background-size: 100% auto;
                        background-position: center;
                        background-image: url(/CFImages/Icons/chevron-right-secondary.svg);
                    }
                }
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Universal Styles - Shared across cardViews and detailViews */
/****************************************************************************************************/
.audioButton {
    padding: 0rem;
}

.audioFile {
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0;
    display: inline;

    a {
        padding: 0;
        display: inline-block;
        color: $secondaryColour;
        display: block;
        line-height: 0;
        display: inline;

        .icon {
            transition: $transition;
            filter: grayscale(1) brightness(1.6); // turn the icons from teal to grey so that we can animate them back to teal on hover
        }

        &:focus,
        &:hover {
            .icon {
                filter: grayscale(0) brightness(1); // turn the icons from teal to grey so that we can animate them back to teal on hover
            }
        }

        &:active {
            filter: brightness(0.5);
        }
    }
}

.viewAllMoreWrapper {
    text-align: center;
}

.viewMoreOuter {
    margin: 0rem auto 3rem;
    text-align: center;
}
/* #region Dates */
span.dates {
    /*text-transform: uppercase;*/
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;

    time {
        font-size: inherit;
        /*text-transform: uppercase;*/
    }
}
/* #endregion */
/* #region Content Type Labels */
.contentTypeLabels {
    color: $primaryColour;
    display: inline-block;
    @include smallcaps;
    letter-spacing: $letterSpacing;
    font-size: 0.7rem;
    margin-bottom: 1.4rem;

    span.labelValuesSeperator {
        &:before {
            content: ": ";
        }
    }
    // filter links
    a {
        color: inherit;
        text-decoration: none;

        span.contentType {
        }

        span.subType {
        }
    }
    // if no hyperlinks chosen
    span.contentType {
    }

    span.subType {
        display: inline-block;
    }
}

.meta {
    color: $primaryColour;
    font-size: 0.7rem;
    font-weight: $fontWeightNormal;
    letter-spacing: $letterSpacing;
    margin-bottom: 0.6rem;

    .separator {
        color: inherit;
        margin: 0 0.2rem;
    }
}
/* #endregion */
/* #region Iframes + Videos + Embeds */
.embedWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
/* #endregion */
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Social & Sharing Links */
/****************************************************************************************************/

.printAndShare {
    display: flex;
}

ul.socialLinks,
.cmsContent ul.socialLinks {
    @include grid(auto, 1rem, "li");
    font-size: 1.2rem;
    flex-wrap: nowrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;

    li {
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        a {
        }

        &:first-child {
            margin-left: 0;
        }
    }
}
/** ShareThis (social sharing links) *****************************************************************/
.shareThis {
    .sharingLinks {
        display: none;
    }
}
// Sharing links (ShareThis tooltip/modal)
ul.sharingLinks {
    background-color: white;
    display: flex;
    padding: 1rem 1rem 1rem .5rem;

    li {

        button {
            cursor: pointer;
            border: none;
            padding: 0;
            background-color: transparent;
            background-image: none;
            color: $greyLight;
            font-size: .5rem;

            svg {
                font-size: .5rem;
                margin-right: .5rem;
            }

            i { // icons
                color: inherit;
            }

            &:hover, &:focus &:active {
                color: $grey;
                background-color: transparent;
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Sliders - .sliderView  Currently using SPLIDE */
/****************************************************************************************************/
/* Splide sliders */
.splide {
    // fight CLS on most sliders.
    visibility: visible;
    /* Show only the first slide ONLY until the slider is initialized */
    &:not(.is-active) {
        .splide__list {
            width: 100%;
        }

        .splide__slide {
            width: 100%;
            display: flex;

            &:not(:first-child) {
                display: none;
            }
        }

        .splide__arrow {
            display: none;
        }
    }
    /* slider initialized */
    &.is-active {
        .splide__slide {
            display: flex;
        }
    }

    .splide__list {
        &.sliderView { // fix common issues with listView class
            & > li.splide__slide {
                margin: 0;
                width: auto;
            }
        }
    }
    /* Arrows */
    .splide__arrow {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: white;

        &.splide__arrow--next svg {
            transform: translateX(10%); // makes the FA chevron look more centered
            margin-left: 0;
        }

        &.splide__arrow--prev svg {
            transform: translateX(-10%); // makes the FA chevron look more centered
            margin-left: 0;
        }
    }

    &.noSliderNeeded {
        .splide__arrows {
            display: none;
        }
    }

    ul.splide__pagination {
        li {
            .splide__pagination__page {
                padding: 0;
            }
        }
    }
}

.slider {
    .slide {
        min-height: 500px;
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Single Slide Styles */
/****************************************************************************************************/
.slide {
    article {
        .innerContent {
            position: relative;
            height: 100%;

            .itemImage {
                position: relative;

                .bgImgWrapper {
                    @include aspectRatioBox(16 9, a);
                }
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Home Slider Only */
/****************************************************************************************************/

.goo {
    font-size: 4.5rem;
    font-size: clamp(2.5rem, 1.6101rem + 3.5595vw, 4.5rem);
    line-height: 1.35;
    display: inline;
    box-decoration-break: clone;
    background: #fff;
    padding: .5rem 2rem;
    filter: url('#goo');
}

.homeSlideshow:not(.flat) {
    position: relative;

    .outsideCardContent {
        position: absolute;
        z-index: 2;
        bottom: -1.2rem;
        right: -.1rem;

        h1 {
            text-align: right;
        }
    }

    .splide__arrows {
        display: none;
    }

    ul.splide__pagination {
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        right: initial;
        bottom: initial;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;
        width: $container;
        align-items: flex-end;
        max-width: calc(100% - 4rem);


        li {
            .splide__pagination__page {
                width: 0.5rem;
                height: 0.5rem;
                margin: 0.5rem 0;
                background-color: $greyLight;

                &.is-active {
                    transform: scale(1);
                    background-color: $primaryColour;
                }
            }
        }
    }

    .splide__slide {
        position: relative;
        min-height: 650px;
        padding: 0;

        .coverImg {
            object-fit: cover;
            border-radius: 2rem;
            overflow: hidden;

            .imgWrapper {
                height: 100%;
                filter: blur(15px);
                //transform:scale(1);
                transition: all 1s ease;
                //transform-origin: bottom left;
                //transition-delay: 1s;
            }
        }
        // The slide Image
        .cardContent {
            height: 100%;
            width: 100%;

            h1 {
                color: #fff;
                position: absolute;
                bottom: 0; // maybe move up with the slide height increase;
                padding: 0;
                margin: 0;
                right: -1px;
                text-align: right;
                font-size: 5rem;
                line-height: 1;
                z-index: 1;
                // transition: all 2s ease;
            }

            .content {
                font-size: 1.1rem;
                font-weight: $fontWeightThin;
                color: #fff;
            }

            .button {
                display: inline-block;
                margin-top: 2rem;
            }
        }

        &.is-visible {
            .coverImg {
                .imgWrapper {
                    filter: blur(0);
                    transform: scale(1.02);
                }
            }

            .cardContent {
                h1 {
                    color: $secondaryColour;
                    opacity: 1;
                    /* causing issues when bottom h1 was 100% of screen */
                    /*&:before {
                        content: "";
                        position: absolute;
                        left: -60px;
                        bottom: 0px;
                        height: 60px;
                        width: 60px;
                        border-bottom-right-radius: 50%;
                        background: transparent;
                        box-shadow: 10px 30px 0 0 #fff;
                    }*/

                    &:after {
                        content: "";
                        position: absolute;
                        top: -65px;
                        right: 0px;
                        height: 59px;
                        z-index: 0;
                        width: 45px;
                        border-bottom-right-radius: 50%;
                        background: transparent;
                        box-shadow: 0px 35px 0 0 #fff;
                    }
                }
            }
        }
    }
}

.homeSlideshow.flat {
    position: relative;

    .splide__slide {
        position: relative;
        min-height: 650px;
        padding: 0;

        .coverImg {
            object-fit: cover;
            border-radius: 2rem;
            overflow: hidden;

            .imgWrapper {
                height: 100%;
                animation: BlurIn;
                animation-duration: 3s;
                animation-fill-mode: forwards;
                animation-timing-function: cubic-bezier(.86,0,.07,1);
                animation-delay: 0;
            }
        }
        // The slide Image
        .cardContent {
            height: 100%;
            width: 100%;

            h1 {
                color: #fff;
                position: absolute;
                bottom: 0; // maybe move up with the slide height increase;
                padding: 0;
                margin: 0;
                right: -1px;
                text-align: right;
                font-size: 5rem;
                line-height: 1;
                z-index: 1;
                animation: ColourIn;
                animation-duration: .5s;
                animation-fill-mode: forwards;
                animation-timing-function: cubic-bezier(.86,0,.07,1);
                animation-delay: 0;
            }
        }
        // }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region LIST VIEWS - .listView THIS ONLY CONTROL THE GRIDS and number of ITEMS - NOT THE CARDS */
/****************************************************************************************************/
.listView { // same as .fixedSize.scales // Defaults to 16rem (as defined in variables file)
    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(min(var(--card-width),100%), 1fr));
    gap: var(--card-gap);
    margin-bottom: 2rem;
    --listViewArticleIndex: none; // For animations
}
/* ### Content Specific Sizing of Grids if required exact dimensions can be paired with default or fixedSizeScales or .fixedSize ### */
.listView.teammembers {
    --card-width: 18.5rem;
}

main .listView.offices {
    --card-width: 100%;

    .cardView.office {
        display: flex;
        gap: 2rem;

        .cardContent {
            width: 40%;
            border-radius: $borderRadius;
            background-color: $primaryColour;
            padding: 2rem 3rem;

            h2 {
                border-bottom: solid 1px rgba(#fff, .3);
                padding-bottom: 1rem;
            }

            address {
                margin-top: 1rem;
            }

            ul, address, p {
                z-index: 1;
                position: relative;
            }
        }

        .imgWrapper {
            width: 60%;

            img {
                border-radius: $borderRadius;
                aspect-ratio: 2;
            }
        }
    }

    li:nth-child(even) .cardView {
        flex-direction: row-reverse;

        .cardContent {
            &:after {
                transform: scaleX(-1);
            }
        }
    }
}
/* fixedSize class is a card that has a fixed pixel width that never scales and only wraps (should always be less than 320px) */
.listView.fixedSize {
    grid-template-columns: repeat(auto-fill,minmax(var(--card-width), var(--card-width)));
}

.listView.fixedSizeScales {
    grid-template-columns: repeat(auto-fit, minmax(min(var(--card-width),100%), 1fr));
}
/* defined number of columns class */
.listView.gridCols {
    grid-template-columns: repeat(var(--column-count),1fr);
}

.listView.gridCols.oneCol {
    --column-count: 1;
}

.listView.gridCols.twoCol {
    --column-count: 2;
}

.listView.gridCols.threeCol {
    --column-count: 3;
}

.listView.gridCols.fourCol {
    --column-count: 4;
}

.listView.gridCols.fiveCol {
    --column-count: 5;
}

.listView.gridCols.sixCol {
    --column-count: 6;
}

.sliderView.expertises {
    .cardView {
        animation: fadeInLeft;
        animation-duration: .4s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(.86,0,.07,1);
        animation-delay: calc(var(--listViewArticleIndex)*150ms);
        opacity: 0;
        border-radius: $borderRadius;
        position: relative;
        overflow: hidden;

        .imgWrapper {
            position: absolute;
            top: 0;
            height: 100%;
            z-index: 0;

            img {
                transition: $transition;
            }
        }

        .cardContent {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: flex-end;
            height: 100%;
            min-height: 350px;

            h3 {
                font-size: 1rem;
                @extend %roundedEdges;
            }
        }

        &:hover {
            .imgWrapper {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.insightsWrapper {
    margin: 5rem 0;

    .listView.insights {
        --card-width: 100%;

        .cardView.insight {
            display: grid;
            grid-template-columns: 1fr 1fr 100px;
            gap: 0 4rem;
            border-top: solid 1px $greyXXLight;
            padding: 2.5rem 0 1rem;

            .button {
                justify-self: flex-end;
            }
        }
    }
}
/* Responsive resizing of gridsCols */
@media (max-width: $L_Max) {
    .listView.gridCols {
        /* Go down to 4 */
        &.fiveCol, &.sixCol {
            --column-count: 4;
        }
        /* Go down to 3 */
        &.fourCol {
            --column-count: 3;
        }
    }
}

@media (max-width: $M_Max) {
    /* Go down to 3 */
    .listView.gridCols {
        &.fiveCol, &.sixCol {
            --column-count: 3;
        }
    }
    /* Go down to 2 */
    .listView.gridCols {
        &.fourCol, &.threeCol {
            --column-count: 2;
        }
    }
}

@media (max-width: $S_Max) {
    /* Go down to 2 */
    .listView.gridCols {
        &.fiveCol, &.sixCol {
            --column-count: 2;
        }
    }
    /* Go down to 1 */
    .listView.gridCols {
        &.twoCol, &.threeCol {
            --column-count: 1;
        }
    }
}

@media (max-width: 600px) {
    /* Go down to 1 */
    .listView.gridCols {
        &.fourCol, &.fiveCol, &.sixCol {
            --column-count: 1;
        }
    }
}
/* DOWNLOADABLE QR CODES SECTION*/
.listView.QRCodes {
    --card-width: 100%;

    ul li {
    }

    .cardView.teamQRCode {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        padding: 1rem;
        border: 1px solid #000;
        background-color: #fff;
        color: $bodyFontColour;
        justify-content: space-between;
        align-items: flex-start;

        a {
            color: $bodyFontColour;
        }

        .imgWrapper {
            width: 150px;

            img {
                object-fit: contain;
            }
        }

        .QRCodes {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .biolink, .biocardlink {
                h3 {
                    font-size: 1.1rem;
                    margin: 0.5rem 0;
                    color: inherit;
                }

                img {
                    width: 200px;
                    margin: 1rem 0 0 0;
                }
            }
        }
    }
}
/* END DOWNLOADABLE QR CODES SECTION*/
/* Related Content Lists (if different) */
/*
.related .listView.related {
}
*/
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region CARDS - .cardView */
/****************************************************************************************************/
/* Card View Animations */
.listView .cardView {
    /* animation: fadeInUp;
        animation-duration: .4s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(.86,0,.07,1);
        animation-delay: calc(var(--listViewArticleIndex)*150ms);
        opacity: 0;*/
}
/* Shared/Common Card Views Styles */
.cardView {
    height: 100%;
    width: 100%;
    /* Title */
    h3 {
        font-size: 1.2rem;
        line-height: 1.1;
        margin: 0;
    }
    /* Dates */
    span.dates {
    }
    /* Authors */
    div.authorTeam {
        a {
        }
    }
    /* Summary */
    div.summary {
    }
    // .cardContent is only on Image Cards and ImageOverlay Cards
    .cardContent {
        padding: 1.5rem;
    }
}

.cardView.imageCard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: $borderRadius;
    box-shadow: $boxShadowAlt;

    .imgWrapper {
        position: relative;
        max-height: 250px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 1.4;
        }
    }

    span.featured {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        padding: 4px 8px;
        border-radius: 0.25rem;
        background-color: $primaryColour;
        font-size: 0.9rem;
        color: #fff;
        text-transform: uppercase;
    }

    .cardContent {
        position: relative;
        padding: 1.5rem 1rem;
    }

    &.withScrollbar {
        /* scrollbar option*/
        .cardContent {
            margin: 1.5rem 0.75rem 1.5rem 0.25rem; // make room for scroller
            padding-top: 0;
            max-height: 400px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0.375rem;
            }

            &::-webkit-scrollbar-track {
                box-shadow: 0;
                border-radius: 1rem;
            }

            &::-webkit-scrollbar-thumb {
                background: $greyXLight;
                border-radius: 1rem;
            }
        }
    }

    &:hover {
        .imgWrapper {
            img {
                transition: all 0.6s ease;
                transform: scale(1.2);
            }
        }
    }
}

.cardView.imageOverlayCard {
    position: relative;
    height: 100%;
    min-height: 200px;

    .overlayWrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .imgWrapper {
            width: 100%;
            height: 100%;

            img {
                transition: all 0.6s ease;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 0%;
            }
        }
        /* zoom in the image */
        &:hover {
            .imgWrapper {
                img {
                    transform: scale(1.2);
                }
            }
        }
        // This is the same wrapper as itemContent so those classes will apply
        .overlay {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .overlay.cardContent {
        }
    }
}
/* Content Specfic Card Views Styles */
/* Team Card View */


.teammembersWrapper.splide {
    &.related {
        .splide__arrows {
            right: inherit;
            width: 100px;
            bottom: 4rem;

            .splide__arrow {
                position: absolute;
            }
        }
    }

    .splide__arrows {
        position: absolute;
        right: -3rem;
        bottom: 0;


        .splide__arrow {
            color: #000;
            margin: 0;
            transform: none;
            position: static;

            svg {
                transform: none;
            }

            &.splide__arrow--prev svg {
            }
        }
    }

    .slide {
        padding: 0;
        height: 400px !important;
        //width: calc(25% - 1rem) !important;
        transition: $transition;
        border-radius: $borderRadiusSmall;
        overflow: hidden;
        display: flex;
        align-items: flex-end;

        &:hover {
            //width: 50% !important;
            transform: scale(1.02);

            .teammember {
                .imgWrapper {
                    transform: none;
                }

                .cardContent {
                    .contactInfo {
                        opacity: 1;
                        transition: $transition;
                        display: block;
                    }
                }
            }
        }

        &.is-active {
            padding: 0;
            //width: 50% !important;
            transition: $transition;
        }

        .teammember {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;

            .cardContent {
                position: relative;
                transition: $transition;
                @extend %roundedEdges;
                padding: 1rem;
                margin: 1rem;

                .contactInfo {
                    opacity: 1;
                    transition: $transition;
                    //display: none;
                }
            }

            .imgWrapper {
                height: 100%;
                position: absolute;
                width: 100%;
            }
        }
    }

    @media only screen and (max-width:1200px) {
        .splide__arrows {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            width: 100%;

            .splide__arrow--prev {
                position: absolute;
                right: 3rem;
                top: 0;
            }

            .splide__arrow--next {
                position: absolute;
                right: 1rem;
                top: 0;
            }
        }

        .splide__list {
            margin-bottom: 2rem !important;
        }

        .slide {
            width: calc(50% - 1rem) !important; // to account for the gap
            &:hover {
                width: calc(50% - 1rem) !important;
            }
        }
    }

    @media only screen and (max-width:650px) {

        .slide {
            width: 100% !important;

            &:hover {
                width: 100% !important;
            }
        }
    }
}

.cardView.teammember:not(.assistant) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 0;
    height: 400px !important;
    transition: $transition;
    border-radius: $borderRadiusSmall;
    overflow: hidden;
    position: relative;

    .button {
        position: absolute;
        top: 0;
        left: 1rem;
        z-index: 3;
        padding: .75rem;
        transition: $transition;
    }

    .imgWrapper {
        height: 100%;
        position: absolute;
        width: 100%;
        transition: $transition;
    }

    &:hover {
        .button {
            background-color: $secondaryColour;
            transform: rotate(-45deg);
        }

        .imgWrapper {
            transform: scale(1.1);
        }
    }
    /* content wrapper  */
    .cardContent {
        position: relative;
        transition: $transition;
        @extend %roundedEdges;
        padding: 1rem;
        margin: 1rem;
        min-height: 110px;

        ul.socialLinks {
            justify-content: space-between;

            li {
                font-size: 1rem;
            }
        }
    }
    // in case no gender pronouns
    .spaceFiller {
        height: 1.5rem;
    }


    h3 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: .5rem;
    }


    .contactInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        li {
            .prefixLabel {
                display: inline-block;
                margin-right: 0.3em;
            }

            a {
                font-family: inherit;
                text-decoration: none;

                &:focus,
                &:hover {
                    text-decoration: underline;
                }

                &:active {
                    text-decoration: underline;
                }
            }
        }
    }
} 

.cardView.teammember.assistant {
    p.title {
        margin-bottom:.25rem;
        font-size:1.1rem;
    }
}
/* Expertise: Card View*/
.cardView.expertise {
    h3 {
        margin-top: 0;
        color: $bodyFontColour;
        padding-bottom: 1em;
        text-decoration: none;
        word-break: break-word; // added at morgan's request to prevent awkward overlapping with long expertise titles
        hyphens: auto;

        &:after {
            width: 3.8rem;
        }

        a {
            color: inherit;
            text-decoration: none;

            &:focus,
            &:hover {
                color: $secondaryColour;
            }

            &:active {
                color: $secondaryColourDark;
            }
        }
    }

    .button.reverse.arrow {
        display: block;

        &:after {
            right: 1.5rem;
            top: .9rem;
            position: absolute;
        }
    }
}
/* Insight CardView */
.cardView.insight {
}
/* Ranking CardView */
.cardView.ranking {
    margin: 0;
    position: relative;
    padding-bottom: 2rem;
    position: relative;

    .cardContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0.25rem;

        span {
            display: block;
            margin: 0.2rem;
        }

        span.rankingPublisher {
            @include smallcaps;
            color: $primaryColour;
            font-size: 0.7rem;
            text-align: center;
        }

        span.rankingAccolade {
            color: $secondaryColour;
            font-weight: $fontWeightMedium;
            font-size: 1.2rem;
            max-width: 80%;
            text-transform: none;
            text-align: center;
        }
        /* optional item */
        span.rankingLocation {
            color: $grey;
            font-size: 0.7rem;
            text-transform: uppercase;
        }

        span.yearAwarded {
            color: $primaryColour;
            font-size: 1.2rem;
            position: absolute;
            bottom: 0.5rem;
        }
    }
}
/* Testimonial CardView */
.cardView.testimonial {
    blockquote {
        margin: 0;
        @extend %doubleQuoteBefore;

        cite {
            display: block;
            margin-top: 1.4rem;
            font-size: 1rem;
        }
    }
}
/* Global Alert CardView */
.cardView.globalalert {
    padding: 0.5rem 0;
    position: relative;
    display: flex;
    align-items: center;

    .alertIcon {
        padding-right: 1rem;

        svg {
            font-size: 1.2rem;
            color: white;
        }
    }

    .alertContent {
        // The alert content itself (style content in here)
        a.alertLabelLink {
            text-decoration: underline;
            display: block;
            margin-bottom: 0.25rem;
            font-size: 0.9rem;
        }

        .optionalContent {
            font-size: 0.8rem;

            p {
                margin: 0;
                font-size: 0.8rem;
            }

            a {
                text-decoration: underline;
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region DETAIL VIEWS - .detailView */
/****************************************************************************************************/
/* Shared detailView Styles */
.detailView {
    .topperSection { // For detail pages without taglines
        position: relative;
        margin: 0;

        .meta {
            color: $primaryColour;
            margin-bottom: 0.6rem;
            text-transform: uppercase;
            font-size: 0.8rem;

            .separator {
                margin: 0 0.4rem;
            }
        }

        .contentTypeLabels {
            font-size: inherit;
        }

        h1 {
            color: $secondaryColour;
            font-size: 3rem;
            margin-top: 0;
        }

        hr {
            margin: 2rem 0 0;
        }

        .topperImage {
        }

        .authorTeam {
            a {
                color: $greyDark;

                &:hover,
                &:focus {
                    color: $secondaryColour;
                }
            }
        }
        /* print / share buttons */
        .buttons {
        }
    }

    .overview {
        margin-top: 0;

        .readMoreButtonWrapper {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .toggleReadMore {
                margin: 0 auto;
            }
        }
    }
    /* Social */
    div.socialAndSharing {
        display: flex;
        @include grid(auto, 1rem, "ul");
        padding: 1rem 0;

        ul.socialLinks {
            font-size: 1.8rem;

            li {
                align-items: flex-end;
            }
        }

        ul.sharingLinks {
            font-size: 1.8rem;

            li {
                align-items: flex-end;
            }
        }
    }
     
    .sidebarGrid {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 4rem;

        .sideContent {
            .innerWrapper {
                background-color: $secondaryColour;
                border-radius: $borderRadius;
                padding: 2rem;
                margin-bottom:2rem;

                h2 {
                    font-size: 1.2rem;
                    font-weight: 700;
                }
            }
        }

        @media only screen and (max-width : $S_Max) {
            flex-direction: column-reverse;

            .sideContent {
                width: 100%;
            }
        }
    }

    .borderRadiusGreyWrapper {
        padding: 2rem;
    }
}
/* Content Specfic detailView Styles */
/* Team Detail View */
.detailView.teammember {
    .bioTopContent {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 2rem;
        color: #fff;
        margin-bottom: 4rem;


        .bioMobileButtons {
            visibility: hidden;
            position: absolute;
            top: 2rem;
            right: 1rem;
            z-index: 5;

            @media only screen and (max-width: $S_Mid) {
                visibility: visible;

                a {
                    background-color: $primaryColour;
                    border-radius: 100%;
                    height: 2rem;
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: .5rem;
                    position: relative;
                    cursor: pointer;
                    transition: $transition;

                    &:before {
                        content: none;
                    }

                    svg {
                        color: #fff;
                    }

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }

        .bioPhotoWrapper {
            flex: auto;
            display: flex;
            width: 100%;
            flex-basis: auto;
            position: relative;

            .imgWrapper {
                img {
                    aspect-ratio: 7/4;
                    border-radius: $borderRadius;
                }
            }
        }

        .bioContact {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            font-size: 90%;
            border-radius: $borderRadius;
            background-color: $primaryColour;
            position: relative;
            padding: 1rem 2rem;

            > * {
                position: relative;
                z-index: 2;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                transform: scaleX(-1);
                background: url(/images/default-source/default-album/wavy-lines.svg) no-repeat -100px 150px;
                background-size: cover;
                height: 100%;
                width: 100%;
                opacity: .3;
                z-index: 1;
            }

            .nameDesignationsPronunciation {
                display: flex;
                flex-wrap: wrap;
                //align-items: center;
                justify-content: flex-start;
                align-items: center;
                margin: 1rem 0 0 0;
                padding: 0.25rem 0;
                /* title */
                h1 { // name
                    position: relative;
                    font-size: clamp(1.4rem, 0.6881rem + 2.8476vw, 3rem);
                    margin: .25rem 0;

                    span {
                        font-size: 60%;
                        font-weight: 300;
                    }

                    &:after {
                        content: none;
                    }
                }
                /* designations */
                .designations {
                    display: block;
                    margin: 0;
                }
                /* audio */
                .audioFile {
                    display: inline-block;
                    margin: 0;
                    padding-left: 1rem;
                    font-size: 1.4rem;

                    a {
                        color: #fff;
                    }

                    a:hover {
                        color: $primaryColourLight;
                    }
                }
            }


            h3 {
                margin: 0 0 .5rem;
            }

            .calltobars {
                display: flex;
                justify-content: space-between;
                border: solid 1px $grey;
                padding: .5rem 0;
                margin-bottom: .5rem;
                border-left: none;
                border-right: none;

                span {
                    text-transform: uppercase;
                    font-weight: 700;
                }

                ul {
                    display: flex;
                    justify-content: flex-end;

                    li {
                        padding-left: .25rem;
                    }
                }
            }


            .contactInfo {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 1.5rem 0 .5rem;

                .prefixLabel {
                    color: #fff;
                }
            }

            .printAndShare {
                font-size: 1.5rem;

                a {
                    margin-right: 1rem;

                    &:hover {
                        color: $tertiaryColour;
                        cursor: pointer;
                    }
                }
            }

            ul.socialLinks {
                justify-content: flex-start;

                li {
                    display: inline-block;
                    font-size: 1rem;
                    margin-left: 0;
                    margin-right: 2rem;

                    a:hover {
                        color: $primaryColour;
                    }
                }
            }

            .related.teammembersWrapper.assistant {
                border-top: solid 1px rgba(#fff,.5);
                margin-top: 1.5rem;
                padding-top: 1.5rem;

                .listViewTitle {
                    font-size: 1.2rem;
                    padding-bottom: 1rem;
                    display: block;
                }

                .cardView.teammember {
                    height: inherit !important;

                    .cardContent {
                        background: none;
                        padding: 0;
                        margin: 0;

                        p {
                            margin: 0;
                        }

                        .contactInfo {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region QR Codes and Online Business Card */
/****************************************************************************************************/
/* The QRCode Image itself */
span.QRCodeWrapper {
    display: flex;
    align-items: center;
    width: fit-content;
    flex-direction: column;
    border: 1px solid black;
    background: #fff;
    margin: 1rem 0;
    padding: 0.5rem;
    //optional wrapper around img and text
    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 100px;
    }
    //Optional text below qr code
    span.QRCodeLabel {
        font-size: 0.8rem;
        //@include button;
    }
}
/* Modal version */
#QRModal {
    display: none;
}

#QRModalMobile {
    display: none;
}

#QRModal {
    max-width: 100%;
    background-color: white;
    padding: 1rem;
    border: $border;
    border-radius: 1rem;

    span.QRCodeWrapper {
        border: none;
        margin: 0;
        padding: 0;

        img {
            width: 280px;
        }
    }
}
/* THE BIO CARD (Online Business Card) */
.bioCardTemplate {
    a {
        text-decoration: none;
    }

    header .bioCardHeader {
        .container.narrow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 0.5rem;
        }
    }

    main .bd {
        .detailView.teammember.bioCard {
            --busCardImageWidth: 140px;

            .busCard {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 2rem;
                padding-top: 4rem;

                .busCardHeader {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    position: relative;
                    z-index: 1;

                    .busCardLogo {
                        display: flex;
                        justify-content: flex-end;
                        height: auto;
                        width: var(--busCardImageWidth);
                        background-image: url(/CFImages/logo.png);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: top right;
                    }

                    .busCardImage {
                        width: var(--busCardImageWidth);
                        border: 3px solid #fff;
                        border-radius: 100%;
                        overflow: hidden;
                        /*.imgWrapper {
                            height: 135px;
                        }*/
                    }
                }



                .busCardContent {
                    background-color: #fff;
                    padding: 6rem 3rem 3rem;
                    width: calc(100% - #{var(--busCardImageWidth) / 2});
                    margin-top: calc(#{var(--busCardImageWidth) / 2 } * -1);
                    position: relative;
                    z-index: 0;

                    h3 {
                        margin: 0;
                        font-size: 1rem;
                    }

                    .fullTitle {
                        .nameAndPronunciation {
                            margin-top: 1rem;

                            h1 {
                                font-size: 1.5rem;
                                font-weight: $fontWeightBold;
                                color: $primaryColour;
                                margin: 0;

                                &:after {
                                    content: none;
                                }
                            }

                            span.audioFile {
                                padding-left: 1rem;
                            }
                        }

                        .designationsAndPronouns {
                            span.designations {
                                font-size: 1rem;
                            }

                            .pronounsWrapper {
                                ul {
                                    li {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                    /* Call To Action Links (View Full Bio, Download Contact) */
                    .callToActionWrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 1rem;
                        justify-content: space-between;
                        margin-top: 1.5rem;

                        .socialLinks {
                            font-size: 1.5rem;
                            display: flex;
                            gap: 1rem;
                            align-items: center;
                        }

                        .button {
                            margin: 0;
                        }
                    }

                    .contactInfo {
                        border-top: solid 1px $greyXLight;
                        padding-top: 2rem;
                        margin: 2rem 0;

                        li {
                            display: flex;
                            gap: 1rem;
                            margin-bottom: 1rem;

                            .icon {
                                background-color: $primaryColour;
                                border-radius: 100%;
                                height: 3rem;
                                width: 3rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &:before {
                                    content: none;
                                }

                                svg {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .additionalInformation {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                /* ShortBio */
                div.shortBio {
                    width: 80%;
                    text-align: center;
                }
                /* Languages Spoken*/
                .languagesSpoken {
                    display: flex;
                    flex-direction: row;
                    gap: 0.25rem;

                    p {
                        font-weight: bold;
                        margin: 0;
                        text-align: right;
                    }

                    span.languages {
                    }
                }

                .related.wrapper {
                    border: $border;
                    border-radius: 1rem;
                    box-shadow: $boxShadow;
                    padding: 2rem;
                    width: 100%;

                    h2.listViewTitle {
                        margin: 0 0 0.5rem 0;
                        font-size: 1.2rem;
                        text-transform: uppercase;
                        color: $bodyFontColour;
                        font-weight: $fontWeightBold;
                    }
                }
                /* Location */
                .related.officesWrapper {
                }
                /* Assistants */
                /* Social Media */
                .socialLinks {
                    font-size: 1.5rem;
                    display: flex;
                    gap: 1.5rem;
                }
            }

            span.QRCodeWrapper {
                img {
                    width: 150px;
                }
            }
        }

        @media only screen and (max-width: $S_Mid) {
            .detailView.teammember.bioCard {
                .busCard {
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: column;

                    .busCardImage {
                        position: relative;
                        left: initial;
                        width: var(--busCardImageWidth);
                    }

                    .busCardContent {
                        margin-top: calc((#{var(--busCardImageWidth) / 2}) * -1);
                        padding: calc(2rem + #{var(--busCardImageWidth) / 2}) 2rem 2rem 2rem;
                        width: 100%;

                        .busCardLogo {
                            background-position: top left;
                        }
                    }
                }
            }
        }
    }

    footer .bioCardFooter {
        text-align: center;
    }
}
/* #endregion */
/****************************************************************************************************/
/* Insight Detail View */
.detailView.insight {
    .topperSection {

        .topperContent {
            .topperTitle {
                h1 {
                    margin-top: 0;
                }
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Expertises + Sectors  */
/****************************************************************************************************/
/* expertise tree view */
.related.expertisesWrapper {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2rem;

    .expertiseandsectors {
        --card-gap: .5rem;
    }
}

.expertisesWrapper.treeViewWrapper {
    ul.level1 {
        visibility: hidden;
    }

    .k-treeview ul.level1 {
        visibility: visible;
    }

    .k-treeview {
        overflow: visible;

        .k-item {
            font-size: 1.2rem;
            border-radius: var(--border-corner-radius) 0;
            border: solid 1px #000;

            .k-link, .k-in {
                font: inherit;
                padding: .75rem 0;
                padding-left: 3rem;
                width: 100%;

                &:active {
                    color: $secondaryColourDark;
                }
            }

            .k-icon {
                width: 2rem;
                height: 2rem;
                left: initial;
                right: 2rem;

                &:before {
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 0.6rem;
                    height: 0.6rem;
                    background-size: 100% auto;
                    background-position: center;
                }

                &.k-i-expand {
                    &:before {
                        background-image: url(/CFImages/Icons/plus.svg);
                    }
                }

                &.k-i-collapse {
                    &:before {
                        background-image: url(/CFImages/Icons/minus.svg);
                    }
                }
            }

            &[data-expanded="true"], &:hover {
                background-color: #000;

                .k-link {
                    color: #fff;
                }

                .k-icon {
                    &.k-i-expand {
                        &:before {
                            background-image: url(/CFImages/Icons/plus-white.svg);
                        }
                    }

                    &.k-i-collapse {
                        &:before {
                            background-image: url(/CFImages/Icons/minus-white.svg);
                        }
                    }
                }
            }
        }
        /* first level */
        .level1 {
            > .k-item {
                margin-bottom: 0.8rem;
            }
        }
        /* second level */
        /*.level2 {
            padding-left: 2rem;
            padding-right: 3rem;
            margin: 0 0 2rem;
            background: #000;
            border-radius: 0 0 60px;

            .k-item {
                font-size: 1rem;

                .k-link, .k-in {
                    border-bottom: none;
                    color: #fff;
                    padding: 1.2rem 0;
                    padding-left: 2rem;

                    &:after {
                        display: none;
                    }
                }

                .k-icon {
                    left: 0rem;
                }

                &:first-child {
                    .k-link {*/
        /*border-top: $border;*/
        /*border-top: none;
                    }
                }
            }
        }*/
        /* third level */
        /*.level3 {
            padding-left: 2.4rem;

            .k-item {
                font-size: 0.8rem;

                &:first-child {
                    .k-link, .k-in {
                        border-top: none;
                    }
                }
            }
        }*/
    }
}

.mainExpertise ul.listView.expertises {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, 100%);

    li {
        display: flex;
    }

    > li:nth-child(odd) {
        .cardView {
            flex-direction: row-reverse
        }
    }

    .cardView.expertise {
        display: flex;
        flex-direction: row;
        flex: 1 1 50%;
        gap: 2rem;

        .itemImage, .itemContent {
            flex-basis: 50%;
            height: 100%;
        }

        .itemContent {
            h3 {
                margin-top: 0;
                color: $bodyFontColour;
                text-decoration: none;
            }

            a {
                text-decoration: none;

                &:focus,
                &:hover {
                    color: $secondaryColour;
                }

                &:active {
                    color: $secondaryColourDark;
                }
            }
        }


        .itemImage {
            img {
                aspect-ratio: $aspectRatio;
            }
        }

        .nestedchildview {
            display: block;

            .listView {
                gap: 0;

                li {
                    padding-left: 0;
                    margin: 0;

                    .cardView.expertise {
                        display: block;

                        .button {
                            max-width: 75%;

                            &:hover {
                                color: #fff !important;
                            }
                        }
                    }

                    &:before {
                        content: none;
                    }
                }
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Documents */
/****************************************************************************************************/
.document {
    font-size: 0.8rem;
    background-color: $greyXXXLight;

    a {
        width: 100%;
        display: block;
        padding: 1rem 1.4rem 0.8rem 1.4rem;
        text-decoration: none !important;
        border-bottom: 1px solid $primaryColour;

        .iconExtension {
            width: 1.5rem;
            flex-grow: 0;
            flex-basis: auto;
            align-items: center;
            justify-content: center;
            color: $primaryColour;

            svg {
                color: inherit;
                font-size: 2rem;
            }
        }

        .documentDetails {
            .titleLink {
                margin: 0;
                padding: 0;
                font-size: 1.2rem;
            }

            .grid {
                @include grid("auto");
                display: flex;
            }

            span.col {
                margin: 0 1rem 0 0;
                flex-basis: auto;
                flex-grow: 0;
            }

            span.size {
            }

            span.lastModified {
            }
        }

        .typeIcon {
            display: block;
        }

        .hoverIcon {
            display: none;
        }

        .textExtension {
            font-size: 1rem;
        }

        @include focusAndHover {
            background-color: darken($greyXXXLight, 5%);
            border-color: $secondaryColour;

            .grid {
                .col.iconExtension {
                    color: $secondaryColour;

                    svg.typeIcon {
                        display: none;
                    }

                    svg.hoverIcon {
                        display: block;
                    }
                }
            }
        }
    }
}

.documents {
    margin: 1rem 0;



    ul {
        li {
            color: $primaryColour;
            display: block;
            margin: 0;
            font-size: 1rem;
            transition: $transition;
            background-color: transparent;
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Filtered Content Items */
/****************************************************************************************************/


.filteredContent {
    // Checkboxes
    .checkboxes {
        flex-wrap: wrap;

        label {
            display: flex;
            gap: .5em;
        }

        fieldset {
            legend {
                width: 100%;
                height: auto;
                position: relative;
                margin: 0;
                padding: 0;
                overflow: initial;
            }

            ul.grid.checkboxes {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                li.field.col {
                    flex: 0;
                    font-size: 0.9rem;
                }
            }
        }
    }

    ul.pagination {
        gap: .4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            button {
                padding: 0;
                width: 2rem;
                height: 2rem;
            }
        }
    }

    .filterButtonWrapper {
        display: flex;
        position: absolute;
        right: 0;
        top: -1.5rem;

        .k-icon {
            margin-left: 2rem;
        }
    }

    .k-window {
        position: absolute;
        z-index: 100002;
        top: 0;
        left: 50%;
        transform: scale(1) translateX(-50%) !important;
        max-width: 100%;
    }

    .k-window-titlebar {
        display: none;
    }
}


.fieldGroups {
    /*    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;*/
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 1.2rem;

    > fieldset {
        flex: 1 1 0;
        margin: 0;
        padding: 0;
        border: none;

        legend {
            @include sr-only;
        }
    }
    // Dropdowns
    .k-dropdown {
        width: 100%;

        &:hover,
        &.k-state-border-down {
            .k-dropdown-wrap {
                border-color: $greyLight;
            }
        }

        .k-dropdown-wrap {
            border: $border;
            border-radius: 0;
            box-shadow: none;
            background: none;
            transition: $transition;

            .k-input {
                padding: 1.6rem 1.2rem;
                font-size: 1rem;
                color: $greyDark;
                user-select: none;
            }

            .k-select {
                padding-right: 1.2rem;

                .k-icon {
                    width: 0.6rem;
                    height: 0.6rem;

                    &:before {
                        background-image: url(/CFImages/Icons/plus.svg);
                        background-size: 0.6rem auto;
                    }
                }
            }
        }
    }
}

.k-list-container {
    border: 1px solid $greyLight;
    border-top-width: 0;
}

.k-list-optionlabel,
.k-list .k-item {
    padding: 0.8rem 0.6rem;

    &.k-state-selected {
        background-color: $secondaryColour;

        &:hover {
            background-color: darken($secondaryColour,5%);
        }
    }
}
/* Filters */
.filtersContainer {
    display: block;
    margin: 0 auto;
    width: 60rem;
    max-width: calc(100% - 4rem);
    margin: 3rem auto 4rem;

    fieldset {
        legend {
            @include sr-only;
        }
    }
}
/* Keyword Search */
.keywordSearchWrapper {
    margin-bottom: 1.4rem;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 6rem;

    input[type=search] {
        border: none;
        border-bottom: $border;
        padding-left: 0;
        box-sizing: border-box;
        height: 3.4rem;
        /* the animated underline */
        /*& + .textFieldUnderline {
            width: 100%;
            height: 1px;
            display: block;
            margin: 0;
            margin-top: -1px;
            background-size: 200%;
            background-position: 100% 0%;*/
        /*background-image: linear-gradient(to right, #{$primaryColour}, #{$primaryColour} 50%, transparent 50%, transparent 75%);*/
        /*background-image: linear-gradient(to right, #{$greyLight}, #{$greyLight} 50%, transparent 50%, transparent 75%);
            position: relative;
            z-index: 10;
        }*/

        &:focus,
        &:active,
        &:hover {
            outline: none !important;
            /*border-bottom: 2px solid $primaryColour;*/
            & + .textFieldUnderline {
                background-position: 0% 0%;
                transition: $transitionQuint;
                transition-duration: 250ms;
            }
        }
    }

    .buttons {
        display: flex;
        align-items: stretch;

        .button {
            //width: 2.6rem;

            svg {
                margin: 0;
            }
        }
    }

    input[type=search]:not(:placeholder-shown) ~ .buttons {
        .clearSearch {
            display: block;
            opacity: 1;
        }
    }
}
/* Search by initial (alphabet) */
.searchByInitialContainer {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;

    .alphaList {
        a {
            cursor: pointer;
            text-decoration: none;
            border: 1px solid $primaryColour;
            padding: 0.25rem 0.5rem;
            min-width: 1.5rem;
            min-height: 0.25rem;
            margin-bottom: 0.5rem;
            display: inline-flex;
            justify-content: center;
            font-size: 1rem;

            &.initialFilter {
                &.selected {
                    background-color: $primaryColour;
                    color: white;
                }

                &:hover {
                    background-color: $primaryColour;
                    color: white;
                }
            }

            &.disabledFilter {
                color: $grey;
                cursor: not-allowed;
                opacity: 0.5;
                text-decoration: none;
                border-color: $grey;
            }
        }
    }
}
/* Filtered Content Wrapper */
.filteredContent {
    padding-bottom: 6rem;
    min-height: 30rem;

    .buttonsContainer {
        .button {
            i {
                margin-left: 2rem;
            }

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }

    .loadMoreContainer {
        text-align: center;
        margin: 4rem 0 0;

        button {
            .icon {
                font-size: 0.8rem;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 0.6rem;
                margin-right: 0.6rem;
            }
        }
    }

    .permanentLinkField {
        display: flex;

        button {
            padding: 0.6rem 1rem;
            margin: 0;

            svg {
                margin: 0;
            }
        }
    }
}
/* Filtered Content withSidebar */

.filteredContent.withSidebar {
    .filteredContentOuterGrid.grid {
        display: grid;
        grid-template-columns: 1fr 3fr;

        .filterHeader {
            h3 {
                text-transform: uppercase;
            }
        }

        .filtersContainer {
            /*Filters are below */
            background-color: white;
            box-shadow: $boxShadow;
            padding: 0 0 1rem 0;

            .advancedFiltersContainer {
                /* section title */
                .filterSectionTitle {
                    display: block;
                    margin: 0 0 1rem 0;
                    padding: 0.5rem 1rem;
                    border-bottom: 1px solid $greyLight;
                    border-top: 1px solid $greyLight;
                    background-color: $greyXXLight;
                    color: $bodyFontColour;
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                }

                .fieldGroups {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    gap: 0;
                    // Dropdowns
                    .k-dropdown {
                        width: 100%;

                        &:hover,
                        &.k-state-border-down {
                            .k-dropdown-wrap {
                                border-color: $greyLight;
                            }
                        }

                        .k-dropdown-wrap {
                            border: $border;
                            border-radius: 0;
                            box-shadow: none;
                            background: none;
                            transition: $transition;

                            .k-input {
                                padding: 1.6rem 1.2rem;
                                font-size: 1rem;
                                color: $greyDark;
                                user-select: none;
                            }

                            .k-select {
                                padding-right: 1.2rem;

                                .k-icon {
                                    width: 0.6rem;
                                    height: 0.6rem;

                                    &:before {
                                        background-image: url(/CFImages/Icons/plus.svg);
                                        background-size: 0.6rem auto;
                                    }
                                }
                            }
                        }
                    }
                    // Checkboxes
                    &.checkboxes {
                        flex-wrap: no-wrap;

                        fieldset {
                            flex: 1 1 0;
                            margin: 0 0 1rem 0;
                            padding: 0 1rem 0 1rem;
                            border-bottom: 1px solid $greyXLight;

                            .filterToggle {
                                position: relative;
                                width: 100%;

                                &:after {
                                    content: ' ';
                                    position: absolute;
                                    top: 0rem;
                                    right: 0.8rem;
                                    color: $secondaryColour;
                                    background-image: url(/CFImages/Icons/chevron-down.svg);
                                    background-size: 0.6rem auto;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-color: $greyXXLight;
                                    width: 0.6rem;
                                    height: 0.6rem;
                                    border: 1px solid $greyXXLight;
                                    border-radius: $borderRadius;
                                    padding: 3px;
                                    cursor: pointer;
                                }

                                legend {
                                    font-size: 14px;
                                    text-transform: uppercase;
                                    font-weight: $fontWeightBold;
                                    display: block;
                                    margin: 0 0 0.5rem 0;
                                }

                                &.active {
                                    &:after {
                                        background-image: url(/CFImages/Icons/chevron-up.svg);
                                        background-color: $secondaryColour;
                                    }
                                }
                            }

                            .filterToggleContent {
                                border: 1px solid $greyXLight;
                                border-radius: $borderRadius;
                                /* only show when active */
                                height: 0;
                                opacity: 0;
                                overflow: hidden;
                                transition: $transitionQuint;

                                &.active {
                                    height: auto;
                                    opacity: 1;
                                    overflow: auto;
                                    max-height: 260px;
                                    padding: 0.5rem 0;
                                    margin-bottom: 1rem;
                                }
                                /* The search input for each filter */
                                input.option-filter-textbox {
                                    width: calc(100% - 2rem);
                                    margin-left: 1rem;
                                    border-radius: $borderRadius;
                                    height: auto;
                                    padding: 0.5rem 1rem;
                                    margin-bottom: 0.5rem;
                                    font-size: 0.8rem;
                                }

                                div.rangeWrapper {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    padding: 0.5rem;

                                    input {
                                        border-radius: $borderRadius;
                                    }

                                    span.minMaxSeperator {
                                        padding: 0 0.5rem;

                                        &:before {
                                            content: "-";
                                        }
                                    }
                                }

                                span.rangeValuesText {
                                    text-align: center;
                                    display: block;
                                    font-size: 12px;
                                    color: $grey;
                                    margin: 0.5rem 0;
                                }

                                ul.checkboxes {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-evenly;
                                    width: 100%;

                                    li.field.col {
                                        position: relative;
                                        flex: 0;
                                        font-size: 0.9rem;
                                        padding: 0.5rem 1rem;
                                        margin: 0;
                                        border-bottom: 1px solid $greyXXLight;

                                        &:last-child {
                                            border-bottom: none;
                                        }

                                        &:hover {
                                            background-color: $greyXXLight;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /* Checkboxes */
    input[type='checkbox'] { // Default (unchecked) state
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 8px;
        appearance: inherit;
        border-radius: 3px;

        + label {
            padding-left: 2rem;
        }

        &:focus {
            outline: none !important;
        }

        &:focus:focus-visible {
        }

        &:checked { // checked icon
            background-color: $secondaryColour;
            border-color: $secondaryColour;
            background-image: url(/CFImages/Icons/check-white.svg);
        }
        /* disabled */
        $disabledColour: $greyLight;

        &:disabled {
            color: $disabledColour;
        }
    }
}
/* Active filters tags/pills */
#activeFilters {
    margin: 1rem 0;


    .activeFilterGroup {
        display: inline-flex;
        flex-wrap: wrap;

        .activeFilterTag {
            border: 1px solid $greyXXLight;
            padding: 0.4rem 1rem;
            cursor: pointer;
            position: relative;
            padding-right: 2.4rem;
            margin-bottom: 0.6rem;
            user-select: none;
            margin-right: 1rem;
            color: $grey;

            &:not(:last-of-type) {
                margin-right: 0.6rem;
            }

            &:after {
                top: 50%;
                content: ' ';
                right: 0.8rem;
                color: $secondaryColour;
                background-image: url(/CFImages/Icons/plus.svg);
                background-size: 0.6rem auto;
                background-position: center;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                transform-origin: center;
                width: 0.6rem;
                height: 0.6rem;
                position: absolute;
            }

            &:hover, &:focus {
                border-color: $greyLight;
                //background-color: $greyXXXLight;
            }

            &:active {
                border-color: $greyLight;
                //background-color: darken($greyXXXLight, 5%);
            }
        }
    }
}

.filtersPopupWrapper {
    width: 100%;
    left: 0 !important;

    .advancedFiltersContainer {
        margin: 0 auto;
        display: block !important;
    }
}

#loadingIndicator {
    height: 200px;
    display: none;
    margin-top: 2rem;

    svg {
        margin: 0 auto;
    }

    &.loading {
        content: "loading";
        line-height: 200px;
        text-align: center;
        display: block;
    }
}

#loadingCounter {
    margin: 2rem 0 2rem;
    color: $grey;
    font-style: italic;
}

#filteredContentResult {
    li.noResults {
        color: $grey;
        width: 100% !important;
        max-width: 100% !important;
        margin: 0;
        flex: initial;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .message {
            font-style: italic;
        }

        .button {
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/*#region Popups / Modals (SHARED) */
/****************************************************************************************************/
.popup_background {
}

.popup_wrapper {

    .button.close {
        top: -2rem;
        right: 0;
        width: 2rem;
        height: 2rem;
        position: absolute;
        border: none;
        padding: 0;
        border-radius: 0;
        background-color: white;
        border: $border;

        &:before {
            background-image: url(/CFImages/Icons/plus.svg);
            background-size: 1rem auto;
            background-position: center;
            background-repeat: no-repeat;
            transform: rotate(45deg);
            transform-origin: center;
            content: ' ';
            display: inline-block;
            position: absolute;
            inset: 0;
        }

        &:focus, &:hover {
            background-color: transparent;

            &:before {
                /*background-image: url(/CFImages/Icons/plus-white.svg);*/
            }
        }

        &:active {
            background-color: transparent;

            &:before {
                /*background-image: url(/CFImages/Icons/plus-white.svg);*/
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Forms + Inputs / Fields */
/****************************************************************************************************/
label {
    cursor: pointer;
}
/* Text Fields */
input {
    color: $greyDark;
    width: 100%;
    height: 3.15rem;
    max-width: 100%;
    padding: 0.2rem 1rem;
    padding-right: 3rem;
    text-overflow: ellipsis;
    border: $border;
    background-color: white;
    font-weight: 100;
    transition: $transitionQuint;

    @include placeholder {
        opacity: 1;
        transition: $transition;
    }
    /*    &:hover, &:focus {
        @include placeholder {
            opacity: 0.4;
        }
    }*/
    @include focusVisible {
        outline: 1px solid $secondaryColour;
        outline-offset: 0;
    }
    // override type-specific styles if needed
    &[type='text'],
    &[type='email'],
    &[type='search'],
    &[type='number'],
    &[type='password'] {
    }
}

textarea {
    width: 100%;
    min-height: 5rem;
    display: block;
    border: $border;
    font-weight: 100;

    &:hover, &:focus {
        @include placeholder {
            opacity: 0.4;
        }
    }

    &:focus {
        outline: 1px solid $secondaryColour;
        outline-offset: 0;
    }
}
/* Select Lists */
select {
    width: 100%;
    height: 3.15rem;
    max-width: 100%;
    color: $greyDark;
    cursor: pointer;
    border: $border;
    padding: 0.2rem 1rem;
    padding-right: 1.6rem;
    font-size: 1rem;
    font-weight: 100;
    line-height: 1;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-size: 0.8rem auto;
    background-image: url(/CFImages/Icons/plus.svg);
    background-repeat: no-repeat;
    background-position: right 1rem center;
    transition: $transitionQuint;

    &:hover {
        border-color: rgba($primaryColour, 0.7);
    }

    &:focus {
        outline: none;
        outline: 1px solid $secondaryColour;
        outline-offset: 0;
    }

    > option {
        text-transform: none;

        &:checked,
        &:hover {
            background-color: $greyXXLight;
        }
    }
}
/* Checkboxes */
input[type='checkbox'] { // Default (unchecked) state
    width: 1px;
    height: 1px;
    margin-right: -1px;
    cursor: pointer;
    position: absolute;
    //visibility: hidden;
    + label {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: $transition;
        padding-left: 2rem;

        &::selection {
            color: inherit;
            background-color: transparent; // stop unwanted user highlighting when clicking filters ...
        }



        &:before,
        &:after { // fake checkbox using icon
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: inline-block;
            position: absolute;
            transition: $transition;
            margin-right: 0.4rem;
            width: 1em;
            height: 1em;
        }

        &:before { // background
            content: " ";
            background-color: white;
        }

        &:after { // unchecked icon
            content: ' ';
            background-image: none;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 80% auto;
            border: $border;
            border-color: $grey;
        }

        &:hover, &:focus {
            color: $secondaryColour;

            &:after {
                border-color: $secondaryColour;
            }
        }
    }

    &:focus {
        outline: none !important;

        + label {
        }
    }

    &:focus:focus-visible {


        + label {
            outline: $outline;
        }
    }

    &:checked { // checked icon
        + label {
            &:after {
                background-image: url(/CFImages/Icons/check.svg);
            }
        }
    }
    /* disabled */
    $disabledColour: $greyLight;

    &:disabled {
        color: $disabledColour;

        + label {
            color: $disabledColour;

            &:before {
            }

            &:after {
                filter: grayscale(1);
                opacity: 0.6;
                border-color: $disabledColour;
            }

            &:hover, &:focus {
                color: $disabledColour;

                &:after {
                    border-color: $disabledColour;
                }
            }
        }
    }
}
/* Radio Buttons */
input[type=radio] {
    height: 1em;
    cursor: pointer;
    display: inline-block;
    width: auto;
}
/*  */
form {
    label {
        cursor: pointer;
    }

    fieldset {
        padding: 0;
        border: none;
    }
    /* Form-only Buttons */
    button {
    }
}
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
// required class for HoneyPot field. DO NOT use display: none here.
.hiddenForHumans {
    position: absolute !important;
    z-index: -10 !important;
    pointer-events: none !important;
    visibility: hidden !important;
    width: 0;
    height: 0;
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Sitefinity Forms Only */
/****************************************************************************************************/
// form container (must work for both <div> and <form> element to work for all SF forms)
[data-sf-role="form-container"] {
    fieldset {
        margin: 1rem 0;

        legend {
            padding: 0 0.4rem;
        }
    }
}
/* Sitefinity File Upload field */
[data-sf-role="file-field-inputs"] {
    cursor: pointer;

    input[type=file] {
        @include button;

        &::-webkit-file-upload-button {
            visibility: hidden;
            @include sr-only;
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/*#region Site Search */
/****************************************************************************************************/
.searchHiddenWrapper {
    background-color: white;
    padding: 0.5rem 0;

    .form-inline {
        width: 100%;
    }

    .searchHidden {
        display: flex;
        align-items: center;
        justify-content: center;

        .formGroup {
            display: flex;
            width: 80%;
            // Search box
            input[type="search"] {
                width: 90%;
                color: $primaryColour;
                background-color: transparent;
                border: 1px solid $primaryColour;
                padding: 0.2rem 3rem 0.2rem 1em;
                font-size: 1rem;
                background-repeat: no-repeat;
                background-position: right 1em center;
                text-overflow: ellipsis;

                @include placeholder {
                    opacity: 1;
                    transition: $transition;
                    color: $primaryColour;
                    text-transform: none;
                }

                &:hover, &:focus {
                    @include placeholder {
                        opacity: 0.4;
                    }
                }

                &:focus {
                    outline: 2px solid $tertiaryColour;
                }
            }
            // Submit button
            .sfsearchSubmit {
                color: $primaryColour;
                background: transparent;
                margin: 0 0 0 -2.5rem;
                padding: 0.25rem 0.5rem;
                font-size: 1.1rem;
                border: none;

                svg {
                    margin: 0;
                }

                &:hover {
                    cursor: pointer;
                    color: $primaryColour;
                }
            }
        }
    }

    button.searchCollapse {
        border: none;

        [class*="fa-"] {
            margin-left: 0;
        }
    }
}
// Search Results
.searchSorting {
    width: 600px;
    max-width: 100%;
    padding-bottom: 2rem;
}

.searchResults {
    > ul.grid {
        @include grid(3,2rem,".col");
    }

    .cardView.searchdefault {
        padding: 2rem 3rem;
    }

    .cardView.insight {
    }

    .nonDynamic {
        padding: 2rem;

        a {
            word-break: break-all;
        }
    }

    @media only screen and (max-width: $M_Max) {
        > ul.grid {
            @include grid(2,2rem,".col");
        }
    }

    @media only screen and (max-width: $S_Mid) {
        > ul.grid {
            @include grid(1,0,".col");
        }
    }

    ul.grid .col {
        margin-bottom: 1rem;
    }
}

.searchResults {
    margin: 2rem 0;

    ul.pagination {
        @include grid(auto, 1rem, "li");
        justify-content: center;

        li {
            margin-top: 1rem;
        }

        li.active {
            a {
                color: $secondaryColour;
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Global Alerts */
/****************************************************************************************************/
/* refer to class .dark for a lot of the styles */
.alertsHeader {
    height: auto;
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Accordions + FAQs */
/****************************************************************************************************/
.accordionGroup.k-group {
    .k-item {
        margin-bottom: 0;
        width: 100%;
        background-color: $secondaryColour;
        white-space: normal;

        .accordionTop {
            padding: .5rem 1rem .5rem 2rem;
            border-bottom: solid 1px $secondaryColourLight;

            .cmsContent {
                color: #fff;
            }

            p {
                margin: 0;
                padding: 0;
            }

            .k-in {
                padding: 0;
            }

            .k-icon:before {
                background-image: url(/CFImages/Icons/plus.svg);
            }

            .k-icon.k-i-collapse:before {
                background-image: url(/CFImages/Icons/minus.svg);
            }
        }

        .k-content {
            display: block;
            background-color: $greyXXLight;
            padding: 2rem;
            white-space: normal;

            .k-in {
                display: none;
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Toggle Sections */
/****************************************************************************************************/
.toggleAction {
}

.toggleContent {
    display: none;

    &:active {
        display: block;
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Image Galleries */
/****************************************************************************************************/
// Gallery slider
.imageGallery.slider {
    //background-color: $greyXLight;
    max-width: 100%;

    .gallerySlider {
        .splide__arrows {
            opacity: 0;
        }

        &:hover {
            .splide__arrows {
                transition: $transition;
                opacity: 1;

                .splide__arrow {
                }
            }
        }

        .splide__slide {
            min-height: 600px;
            height: 70vh;
            max-height: 700px;
            max-width: 100vw;

            > a {
                cursor: zoom-in;
                position: absolute;
                inset: 0;
            }

            img {
                object-fit: scale-down;
                object-fit: cover;
                object-position: 50% 13%;
                height: 100%;
                width: 100%;
            }

            .descriptionWrapper {
                position: absolute;
                top: auto;
                left: 0;
                right: 0;
                width: 100%;
                bottom: 0;
                z-index: 2;
                color: black;
                max-width: 100%;
                background-color: rgba(white, 0.8);
                /*                opacity: 0;
                    transition: $transitionQuint;*/
                .description {
                    font-size: 0.9rem;
                    padding: 2rem;
                    display: inline-block;

                    > :last-child {
                        margin-bottom: 0;
                    }
                }
            }
            /*            &:hover {
                .descriptionWrapper {
                    opacity: 1;
                    .description {
                    }
                }
            }*/
        }
    }

    .gallerySlider,
    .gallerySliderNav {
        padding-top: 1rem;
    }

    .gallerySliderNav {
        @media(max-width: $S_Max) {
            display: none;
        }

        .splide__slide {
            /*opacity: 0.8;*/
            border: 2px solid white;

            &.is-active {
                border: 2px solid $secondaryColour;
            }

            &:before {
                content: ' ';
                background-color: $greyXXXLight;
                position: absolute;
                inset: 0;
                z-index: -1;
            }

            &:after { // overlay
                content: ' ';
                position: absolute;
                inset: 0;
                z-index: 10;
                background-color: rgba(white, 0.1);
                pointer-events: none;
                transition: $transitionQuint;
            }
            /*            .bgImgWrapper {
                position: relative;
                @include aspectRatioBox(4 3);
                opacity: 0.9;

                div.bgImg {
                }
            }

            img {
                opacity: 0.8;
            }*/
            @include focusAndHover {
                &:after {
                    background-color: rgba(white, 0);
                }
                /*opacity: 1;*/
                /*                img,
                .bgImgWrapper {
                    opacity: 1;
                }*/
            }
        }
    }
}

.fancybox-caption {
    display: none !important; // ToDo: just fix the captions instead of hiding this...
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Page - Home Page */
/****************************************************************************************************/
.homePage {
    .topper {
        margin-bottom: 3rem;
    }

    .expertisesWrapper {

        .expertises {
            background-color: $secondaryColour;
        }

        .splide__pagination {
            bottom: -2rem;
        }

        .splide__arrows {
            display: none;
        }
    }

    .aboutUsWrapper .container {
        background-color: $primaryColour;
        border-radius: $borderRadiusSmall;
        padding: 6rem 0;
        text-align: center;

        .cmsContent {
            padding: 0 1rem;
            position: relative;
            z-index: 1;
        }

        .cover {
            opacity: .3;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            transform: rotate(180deg);
            background: url(/images/default-source/default-album/wavy-lines.svg) no-repeat 0 40px;
            height: 100%;
            width: 100%;
            opacity: .3;
            z-index: 0;
        }
    }

    .insightsWrapper {
        margin: 5rem 0;

        .insightsWrapper {
            margin-top: 2rem;
        }
    }

    .careersWrapper {
        .primaryWrapper {
            border-radius: $borderRadius;
            padding: 3rem;

            .button {
                margin-top: 0;
                width: 20rem;
                display: flex;
                justify-content: space-between;
                max-width: 100%;
            }
        }

        margin-bottom: 3rem;
    }
}

.pinkButtonsGrid{
    margin-bottom:2rem !important;
}
/* Lawyer Search (FindASearch) */
.findASearch {
    margin: 0 auto 9em;
    text-align: center;
    width: 28rem;
    max-width: 100%;
    ////
    input[type=text] {
        color: $grey;
        width: 100%;
        height: 3.15rem;
        max-width: 100%;
        padding: 0.2rem 1rem;
        padding-right: 3rem;
        text-overflow: ellipsis;
        background-color: white;
        font-size: 1.2rem;
        font-weight: $fontWeightLight;
        transition: $transition;
        background-image: url(/CFImages/Icons/search.svg);
        background-repeat: no-repeat;
        background-position: center right 1rem;
        outline-offset: -2px;
        border: none;
        padding-left: 0;
        box-sizing: border-box;
        height: 3.4rem;

        &:focus {
            outline: none !important;
        }
    }

    .k-widget {
        /* the animated underline */
        & + .textFieldUnderline {
            width: 100%;
            height: 1px;
            display: block;
            margin: 0;
            margin-top: -1px;
            background-size: 200%;
            background-position: 100% 0%;
            /*background-image: linear-gradient(to right, #{$primaryColour}, #{$primaryColour} 50%, transparent 50%, transparent 75%);*/
            background-image: linear-gradient(to right, #{$greyLight}, #{$greyLight} 50%, transparent 50%, transparent 75%);
            position: relative;
            z-index: 10;
        }

        &:focus,
        &:active,
        &:hover,
        &.k-state-focused {
            outline: none !important;
            /*border-bottom: 2px solid $primaryColour;*/
            & + .textFieldUnderline {
                background-position: 0% 0%;
                transition: $transitionQuint;
                transition-duration: 250ms;
            }
        }
    }
    ////
    .k-widget.k-autocomplete {
        width: 100%;
        color: $grey;
        border: none;
        border-bottom: $border;
        box-shadow: none;
    }
    // the dropdown
    #findALawyerDropdownContainer {
        .k-list {
            .k-item {
                &:first-of-type {
                    display: none !important;
                }
            }
        }

        .k-list-container .k-popup {
        }
    }
}

.findAnything {
    fieldset {
        border: none;
        padding: 0;
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Page - Careers (and child pages) */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Insights (shared styles) - Cards and Detail View  */
/****************************************************************************************************/
.wrapper.careersWrapper {
    .k-treeview {
        .listView.careers {
            --card-width: 100%;
            --card-gap: 0;

            .k-item {
                margin-bottom: .5rem;

                .k-icon:before {
                    background-image: url(/CFImages/Icons/plus-white.svg);
                }

                .k-icon.k-i-collapse:before {
                    background-image: url(/CFImages/Icons/minus-white.svg);
                }

                .k-in {
                    width: 100%;
                    background-color: $primaryColour;
                    padding: 1rem 2rem;
                    white-space: normal;
                    border-radius: 50vh;
                    overflow: hidden;

                    h4 {
                        margin: 0;
                        padding: 0;
                        color: #fff;
                    }
                }

                &[aria-expanded="true"] {
                    .k-in {
                        border-radius: 0;
                    }
                }

                .k-content {
                    display: block;
                    background-color: $greyXXLight;
                    padding: 2rem;
                    white-space: normal;

                    .k-in {
                        display: none;
                    }
                }
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region PDF Generation (Printable Bios) */
/****************************************************************************************************/

#printModal_background {
    background: none !important;
}

#printModal {
    display: none;
}

.printModalWrapper #printModal {
    display: block;
}

#printModal {
    //width: 60rem;
    background-color: white;
    padding: 4rem;
    padding-top: 3rem;
    border: $border;

    .k-close {
        position: absolute;
        right: 4rem;
    }

    .printModal {
    }

    .top {
        p {
            font-style: italic;
        }
    }

    .bottom {
        margin: 1.4rem 0 1.1rem;

        .options {
            ul {
                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.4em;
                }
            }
        }
    }

    .label {
        @include smallcaps;
        letter-spacing: $letterSpacingMid;
        font-size: 0.7rem;
        color: $primaryColour;
        margin-top: 0;
    }

    h3 {
        color: $secondaryColour;
        margin-top: 0;
        margin-bottom: 0.6rem;
    }

    .button.close {
        inset: initial;
        top: 2.6rem;
        right: 2.6rem;
        margin: 0;
    }
    /* button[type=submit] {
        margin-left: auto;
        padding: 0.8em 2em 0.8em 1.6em;

        .icon, svg {
            margin-right: 1rem;
            font-size: 1.5rem;
        }

        .fa-file-download {
            display: none;
        }

        &:focus, &:hover {
            .fa-file-pdf {
                display: none;
            }

            .fa-file-download {
                display: inline-block;
            }
        }
    }*/
}

@keyframes fadeToGreyscaleWhite {
    0% {
        filter: grayscale(0) brightness(1);
    }

    1% {
        filter: grayscale(1) brightness(1);
    }

    100% {
        filter: grayscale(1) brightness(200);
    }
}

html.popup_visible_printModal {
    #wrapper > main {
        filter: blur(5px);
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Utility Classes - Visibility */
/****************************************************************************************************/

.sr-only { // screen-readers only (hide visually only)
    @include sr-only;
}

.hidden {
    display: none !important;
}

.forcedHide {
    display: none !important;
}

.hideOnDesktop {
    display: none !important;
}

.hideOnMobile {
    display: inline-block;
}

.printOnly {
    display: none;
}

.mobileOnly {
    display: none;
    height: 0;
    width: 0;

    @media(max-width:$S_Max) {
        display: block;
    }
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Utility Classes - Position, Display, Float */
/****************************************************************************************************/
/* Floating */
.float-none {
    float: none;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}
/* Positioning */
.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.position-fixed {
    position: fixed;
}
/* Display */
.display-flex {
    display: flex;
}

.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.display-inline {
    display: inline;
}

.display-none {
    display: none;
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Utility Classes - Text Alignment */
/****************************************************************************************************/

.txt-c {
    text-align: center !important;
}

.txt-l {
    text-align: left !important;
}

.txt-r {
    text-align: right !important;
}

.txt-t {
    vertical-align: top !important;
}

.txt-b {
    vertical-align: bottom !important;
}

.txt-m {
    vertical-align: middle !important;
}
/* Table cell content alignment */
/* horizontal alignment */
table .txt-c, table tr .txt-c, table tr td.txt-c {
    @extend .txt-c;
}

table .txt-l, table tr .txt-l, table tr td.txt-l {
    @extend .txt-l;
}

table .txt-r, table tr .txt-r, table tr td.txt-r {
    @extend .txt-r;
}
/* vertical alignment */
table .txt-t, table tr .txt-t, table tr td.txt-t, table tr th.txt-t {
    @extend .txt-t;
}

table .txt-b, table tr .txt-b, table tr td.txt-b, table tr th.txt-b {
    @extend .txt-b;
}

table .txt-m, table tr .txt-m, table tr td.txt-m, table tr th.txt-m {
    @extend .txt-m;
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Utility Classes */
/****************************************************************************************************/
/* ##### Whitespace (padding + margin) ##### */
/*    Examples:
    .mtxl - margin-top-extra-large (add an extra large margin-top)
    .pan  - padding-all-none       (remove padding on all sides)
    .mvs  - margin-vertical-small  (add small margins on the top and bottom)
*/
@include whitespace(($whitespace-xs, $whitespace-s, $whitespace-m, $whitespace-l, $whitespace-xl, $whitespace-xxl, $whitespace-xxxl, $whitespace-xxxxl));
/* ##### subheaders ##### */
/*    Examples: .subheader-s .subheader-m .subheader-l */

$subHeaderSizes: (xs:$subheader-xs,s:$subheader-s, m:$subheader-m,l:$subheader-l,xl:$subheader-xl );

.subheader {
    @include modifiers($map: $subHeaderSizes, $attribute: 'font-size')
}
/* ##### txt colours ##### (replaces old txtGrey txtPrimary etc.)*/
/*    Examples: .txt-primary .txt-secondary .txt-grey .txt-grey-dark */
$txtColours: (white:#fff,black:#000,primary: (base:$primaryColour,light: $primaryColourLight, dark:$primaryColourDark),secondary: (base:$secondaryColour,light: $secondaryColourLight, dark:$secondaryColourDark),tertiary: (base:$tertiaryColour,light: $tertiaryColourLight, dark:$tertiaryColourDark), grey:( base: $grey, dark: $greyDark, xdark: $greyXDark, light:$greyLight,xlight:$greyXLight,xxlight:$greyXXLight, xxxlight:$greyXXXLight ));

.txt {
    @include modifiers($map: $txtColours, $attribute: 'color')
}
/* ##### bg colours ##### (replaces old bgGrey bgDark etc.) */
/*    Examples: .bg-primary .bg-secondary .bg-dark .bg-light .bg-grey .bg-grey-dark .bg-grey-xdark */
$bgColours: (white:#fff,black:#000,dark:(base:$greyDark), light:(base:$greyXXLight), offwhite: (base:$offWhite),primary: (base:$primaryColour,light: $primaryColourLight, dark:$primaryColourDark),secondary: (base:$secondaryColour,light: $secondaryColourLight, dark:$secondaryColourDark),tertiary: (base:$tertiaryColour,light: $tertiaryColourLight, dark:$tertiaryColourDark), grey:( base: $grey, dark: $greyDark, xdark: $greyXDark, light:$greyLight,xlight:$greyXLight,xxlight:$greyXXLight, xxxlight:$greyXXXLight ));

.bg {
    @include modifiers($map: $bgColours, $attribute: 'background-color')
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Utility Classes - Misc */
/****************************************************************************************************/
/* (simple, single-purpose classes intended for use in Siteifnity editors, etc) */
.border {
    border: $border;
}

.borderBottomShort {
    @extend %borderBottomShort;
}

.equalTopMargins {
    > :first-child {
        margin-top: 1rem;
    }
}

.pullQuote {
    padding: 30px 50px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 1.3em;
    font-weight: $fontWeightBold;
}

.subtle {
    color: lighten($primaryColour,15%);
    font-size: 0.8em;
}
/* #endregion */
/****************************************************************************************************/
/*Filtered content*/
.pagination-page {
    color: $primaryColour;

    .selected {
        color: #ffffff;
        background-color: #940e14;
        padding: 4px;
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 90px;
        display: inline-block;
        line-height: 100%;
    }
}

#ClearFiltersWrapper {
    position: fixed;
    z-index: 10000;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    right: 5%;
    bottom: 10%;
    background: $primaryColour;
    border: solid #fff 1px;
    border-radius: 40px;
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
    color: #fff;
    opacity: 0.45;
    transition: all linear 0.15s;
    cursor: pointer;

    span.horizontal-slash {
        position: absolute;
        transform: rotate(35deg);
        height: 3px;
        width: 100%;
        background: #fff;
    }
}

#ClearFiltersWrapper:hover {
    opacity: 1;
}

.goToPageLbl {
    display: block;
    max-width: 100px;
    padding: 3px;
    border-radius: 5px;
    text-align: center;
    margin: 15px auto;
    position: relative;
    transition: all linear 0.15s;

    select {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 999;
        margin: 0 auto;
        height: auto;
    }

    .goToDropdown {
        height: 0px;
        max-width: 80px;
        margin: 0 auto;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .goToDropdown.active {
        height: 140px;
        border: solid $primaryColour 1px;
        border-radius: 6px;
        overflow-y: auto;
    }

    li {
        margin-right: 3px;
    }

    li.selected {
        background: $greyXXXLight;
    }
}

.goToPageLbl:hover {
    color: $primaryColour;
}
/* #endregion */
/****************************************************************************************************/
/****************************************************************************************************/
/* #region Bookmarks */
/****************************************************************************************************/
#BookmarkLoading {
    display: none;
    z-index: 9999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


.bookmark-modal, #UserBookmarkModal {
    display: none;
    background: rgba(0,0,0,0.1);
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;


    .global-bookmark-list {
        .global-bookmark-group {
            margin: 0.5rem 0 0 0;
            padding: 0.25rem 0;

            .group-opts {
                text-align: right;

                a {
                    margin-right: 15px;
                    cursor: pointer;
                }
            }
        }
    }

    #UserBookmarkInnerWrapper {
        height: auto;
        min-height: 450px;
    }

    .bookmark-wrapper, #UserBookmarkInnerWrapper {
        padding: 15px 35px;
        background: #ffffff;
        position: relative;
        max-width: 1300px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;

        #BookmarkGroupNew {
            display: none;
        }

        #BookmarkGroupOpen, #BookmarkGroupClose {
            float: right;
        }

        .bookmark-options {
            margin: 1rem 0 0 0;
            text-align: center;
        }

        .bookmark-options.text-right {
            text-align: right !important;
        }

        .bookmark-title {
            a {
                display: inline;
            }

            a.rm-bookmark {
                float: right;
            }
        }

        .bookmark-options button.btn {
            width: 49%;
        }

        .bm-public, .bm-editable {
        }
    }

    #BookmarkGroupDeleteConfirmDialog, #BookmarkGroupDocumentOptions {
        position: absolute;
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background: #fff;
        padding: 20px;

        h3 {
            margin-top: 0px;
        }

        p {
            padding: 20px;
        }

        .doc-opt-group {
            margin-bottom: 20px;

            .doc-type-options {
                input {
                    margin-right: 20px;
                }
            }
        }

        .doc-related-options {
            margin: 0 20px 15px;
            text-align: left;
            display: inline-block;
        }

        label:before {
            display: none !important;
        }

        label:after {
            display: none !important;
        }

        .doc-param, .doc-param-all {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            position: relative !important;
        }
    }

    .global-bookmark-list {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 400px;
        width: 100%;
        margin: 1rem 0;
        padding: 1rem 2rem;
        border-bottom: $border;

        h3.ownedByTitle {
            display: block;
            margin: 2rem 0 0.5rem 0;
            padding: 0;
            font-size: 0.8rem;
            font-style: italic;
            color: $bodyFontColour;
            line-height: 1rem;
        }
    }

    .bookmark-wrapper {
        max-width: 350px;
    }

    #CloseGlobalBookmarkModal {
        float: right;
    }

    .global-bookmark-group {
        display: none;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: $border;

        .permissionActionsGrid {
            display: grid;
            grid-template-columns: 40% 30% 30%;

            &.layout {
                padding: 0;
            }

            .title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                h3 {
                    margin: 0;
                }
            }

            .actions {
                /* print and deleted */
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;
                font-size: 1rem;
                cursor: pointer;
                color: $bodyFontColour;
            }

            .permissions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap: 1rem;
                position: relative;
                /* public and editable */
                span {
                    /* Checkboxes */
                    input[type='checkbox'] { // Default (unchecked) state
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        position: absolute;
                        top: auto;
                        appearance: inherit;
                        border-radius: 3px;

                        + label {
                            padding-left: 2rem;
                        }

                        &:focus {
                            outline: none !important;
                        }

                        &:focus:focus-visible {
                        }

                        &:checked { // checked icon
                            background-color: $secondaryColour;
                            border-color: $secondaryColour;
                            background-image: url(/CFImages/Icons/check-white.svg);
                        }
                        /* disabled */
                        $disabledColour: $greyLight;

                        &:disabled {
                            color: $disabledColour;
                        }
                    }
                }
            }
        }
    }

    .global-bookmark-group.show {
        display: block; // !important;
    }
}

#BookmarkMessageWrapper {
    display: none;
    position: fixed;
    z-index: 9999;
    border-radius: 8px 0px 0px 8px;
    right: 0;
    bottom: 0;
    max-width: 350px;
    width: 100%;
    -webkit-box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.3);
    box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.3);
    background: $offWhite;

    #BookmarkMessage {
        padding: 20px;
        margin: 0px;
        font-size: 20px;

        .text-success {
            color: $primaryColour;
        }

        .text-danger {
            color: #ce1f1f;
        }

        .text-warning {
            color: #e08f10
        }
    }
}

#DropZone {
    .global-bookmark-group {
        box-shadow: $boxShadow;
        border: 1px solid $greyLight;
        margin: 1rem 0 2rem 0;
        padding-bottom: 2rem;

        .permissionActionsGrid {
            display: grid;
            grid-template-columns: 40% 30% 30%;

            &.layout {
                padding: 0rem 1rem;
                margin: 0;
            }

            .title {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .bmg-sort {
                    margin: 0;
                    padding: 0;
                }

                .group-name-text {
                    &[contenteditable="true"] {
                        border: 1px solid $grey;
                        padding: 0.5rem 3rem 0.5rem 0.5rem;
                        position: relative;
                        cursor: pointer;

                        &:after {
                            content: ' ';
                            background-image: url(/CFImages/Icons/pen.svg);
                            background-repeat: no-repeat;
                            position: absolute;
                            right: 0.5rem;
                            top: 0.5rem;
                            width: 1rem;
                            height: 100%;
                        }
                    }
                }
            }

            .actions {
                /* print and deleted */
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;
                font-size: 1.4rem;
                cursor: pointer;
                color: $bodyFontColour;
            }

            .permissions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap: 1rem;
                position: relative;
                /* public and editable */
                span {
                    /* Checkboxes */
                    input[type='checkbox'] { // Default (unchecked) state
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        position: absolute;
                        top: auto;
                        appearance: inherit;
                        border-radius: 3px;

                        + label {
                            padding-left: 2rem;
                        }

                        &:focus {
                            outline: none !important;
                        }

                        &:focus:focus-visible {
                        }

                        &:checked { // checked icon
                            background-color: $secondaryColour;
                            border-color: $secondaryColour;
                            background-image: url(/CFImages/Icons/check-white.svg);
                        }
                        /* disabled */
                        $disabledColour: $greyLight;

                        &:disabled {
                            color: $disabledColour;
                        }
                    }
                }
            }
        }

        .bmc-sort {
            position: relative;
        }
        /* .bmc-sort::before {
            content: "Group Drag ::before attribute";
            display: inline;
            position: absolute;
            top: -20px;
            left: 0;
        }*/

        .bmg-sort {
            margin: 10px 10px 20px;
            padding-bottom: 5px;
            display: inline-block;
        }

        .bmg-sort {
            //border-bottom: solid $primaryColourLight 1px;

            .fa-grip-vertical {
                font-size: 26px;
            }
        }

        .group-opts {
            float: right;
        }
    }

    .bookmark-items {
        padding: 1rem 1rem;

        .bmc-sort {
            margin: 10px;
        }

        .bookmark-item {
            min-width: 100px;
            margin-bottom: 2rem;

            .bookmark-opts {
                .rm-bookmark {
                    float: right;
                }
            }
        }
    }
    // Sorting
    .bmg-sort.global-bookmark-group {
    }
}
/* to prevent the flicker when bookmarks loaded/unloaded on page */
#BookmarkPageListContainer.hide {
    display: none !important;
}
/* #endregion */
/****************************************************************************************************/
